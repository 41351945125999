import { IComponentConfig } from "@/app/@types/base-component";
import {
    createDoubleFabricProducedDoorConfig,
    createHandleConfig,
    createLockConfig,
    createPatchesSetConfig,
    createRollerSetConfig,
    createSingleFabricProducedDoorConfig,
    createUHolderConfig,
} from "../../configs/hardwares.config";

import { Hardware } from "../../entities/hardware";
import { CreateManyStrategy } from "../base/creation.strategy";

export class SingleSwingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const SINGLE_SWING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
            createPatchesSetConfig().build(),
            createHandleConfig().build(),
            createLockConfig().valueNone().build(),
        ];
        super(SINGLE_SWING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class DoubleSwingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const DOUBLE_SWING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
            createPatchesSetConfig().qty(2).build(),
            createHandleConfig().qty(2).build(),
            createLockConfig().qty(2).valueNone().build(),
        ];
        super(DOUBLE_SWING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class SingleSlidingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const SINGLE_SLIDING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
            createRollerSetConfig().build(),
            createUHolderConfig().build(),
            createHandleConfig().build(),
            createLockConfig().valueNone().build(),
        ];
        super(SINGLE_SLIDING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class DoubleSlidingDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const DOUBLE_SLIDING_DOOR_HARDWARES_CONFIG: IComponentConfig[] = [
            createRollerSetConfig().qty(2).build(),
            createUHolderConfig().qty(2).build(),
            createHandleConfig().qty(2).build(),
            createLockConfig().qty(2).valueNone().qty(2).build(),
        ];
        super(DOUBLE_SLIDING_DOOR_HARDWARES_CONFIG, Hardware);
    }
}

export class KompasSingleDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const KOMPAS_SINGLE_HARDWARES_CONFIG: IComponentConfig[] = [
            createSingleFabricProducedDoorConfig().build(),
        ];
        super(KOMPAS_SINGLE_HARDWARES_CONFIG, Hardware);
    }
}

export class KompasDoubleDoorHardwareStrategy extends CreateManyStrategy {
    constructor() {
        const KOMPAS_DOUBLE_HARDWARES_CONFIG: IComponentConfig[] = [
            createDoubleFabricProducedDoorConfig().build(),
        ];
        super(KOMPAS_DOUBLE_HARDWARES_CONFIG, Hardware);
    }
}
