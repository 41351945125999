import { useDictStore } from "@/stores/dictStore";
import { SystemPredicates } from "@/app/predicates";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import {
    NonSteelGlassPriceMultiplier,
    SteelGlassPriceMultiplier,
} from "@/app/constants/multipicators";
import { Partition } from "../../entities/partition";

export class GlassPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [installedSystemCode, stateCode, partition] = args as [
            string,
            string,
            Partition,
        ];

        const multiplicator = SystemPredicates.isSteelSystem(
            installedSystemCode,
        )
            ? SteelGlassPriceMultiplier
            : NonSteelGlassPriceMultiplier;

        const glass = partition.getActiveGlass();
        const glassThinkness = partition.getActiveGlassThinkness();

        if (!glass || !glassThinkness) {
            return this.getDefaultPrices();
        }

        const pricesFromDict = this.dictStore.findGlassPrice(
            glass.structureType!,
            glassThinkness.structureType!,
            stateCode,
        );

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.price! * multiplicator,
            priceB2B: pricesFromDict.price! * multiplicator,
        };
    }
}
