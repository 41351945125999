import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { Space } from "@/app/domain";
import { unwrapRef } from "@/utils/reactive";

export const useSpacesStore = defineStore(
    "spaces-store",
    () => {
        const spaces = ref<Space[]>([]);
        const activeSpace = ref<Space | null>(null);
        const hasActiveSpace = computed(() => activeSpace.value !== null);

        const $reset = () => {
            spaces.value = [];
            activeSpace.value = null;
        };

        const $restoreSpaces = (spacesFromApi: Space[]) => {
            spaces.value = spacesFromApi;
            activeSpace.value = spacesFromApi[0] || null;
        };

        const setActiveSpace = (space: Space) => {
            activeSpace.value = space;
        };

        const addSpace = () => {
            activeSpace.value = null;

            const space = new Space({ qty: 1 });

            spaces.value.push(space);
            activeSpace.value = space;
        };

        const removeSpace = (space?: Space) => {
            if (!space) return;

            spaces.value = spaces.value.filter(
                (s) => unwrapRef(s.id) !== unwrapRef(space.id),
            );

            console.log(
                "unwrapRef(activeSpace.value?.id) === space.id",
                unwrapRef(activeSpace.value?.id) === space.id,
            );

            if (unwrapRef(activeSpace.value?.id) === space.id) {
                activeSpace.value = null;
            }
        };

        return {
            spaces,
            activeSpace,
            hasActiveSpace,
            removeSpace,
            setActiveSpace,
            addSpace,
            $restoreSpaces,
            $reset,
        };
    },
    { persist: true },
);
