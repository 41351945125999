<template>
  <template v-if="summary">
    <div class="step__right-head">
      <h2>Estimate summary</h2>
      <span v-if="totalArea"
        >square: {{ totalArea?.toSquareFt().format() }} ft.</span
      >
      <SummaryPrice />
    </div>
    <div v-if="summary.type === 'estimate'" class="step__info">
      <h3 class="step__info-title">{{ summary.description }}</h3>
      <p class="step__info-description">{{ summary.title }}</p>
    </div>
    <StepAccordion
      v-for="block in summary.subBlocks"
      :object-code="block.objectCode"
      :key="block.objectCode"
      :title="block.title"
    >
      <SummaryBlock
        :summary-block="block"
        @header-click-edit="headerClickEdit"
        @header-click-delete="headerClickDelete"
      />
    </StepAccordion>
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useActionsStrore } from "@/stores/new/actions.store";
import { useSpacesStore } from "@/stores/new/spaces.store";

import { StepAccordion } from "@/shared";

import SummaryPrice from "./SummaryPrice.vue";
import SummaryBlock from "./SummaryBlock.vue";

const estimateStore = useNewEstimateStore();
const spacesStore = useSpacesStore();
const actionsStore = useActionsStrore();

const activeSpace = computed(() => spacesStore.activeSpace);

const totalArea = computed(() => estimateStore.estimate.totalSquareFt);
const summary = computed(() => actionsStore.summary);

const reloadSummary = () => {};
const headerClickEdit = () => {
  actionsStore.editPartition();
};

const headerClickDelete = (id: string) => {
  actionsStore.removePartition(id);
};

onMounted(() => {
  actionsStore.reloadSummary();
});

watch(
  () => activeSpace.value?.components,
  () => {
    estimateStore.calculateTotalSquareFt();
  },
  { deep: true, immediate: true },
);

defineExpose({ reloadSummary });
</script>

<style scoped>
.step__accordion-block-count span {
  padding-right: 15px;
  width: 15px;
  text-align: center;
}

.step__right-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
}

.step__info {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.step__info-title {
  color: #2491eb;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.step__info-description {
  color: #fff;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

:deep(.step__accordion-total) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

:deep(.step__accordion-block-list) {
  display: grid;
  row-gap: 10px;
  padding: 8px;
}

:deep(.step__accordion-block-dot) {
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 55px;
  margin-top: 6px;
  /* align-self: center; */
}

:deep(.step__accordion-block-right) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 142px;
  column-gap: 10px;
}

:deep(.step__accordion-block-right div) {
  width: 50%;
  text-align: right;
}

:deep(.step__accordion-block-item) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  border-bottom: 0.5 solid rgba(255, 255, 255, 0.5);
}

:deep(.step__accordion-block-left) {
  display: grid;
  grid-template-columns: minmax(0, max-content) 1fr;
  column-gap: 10px;
}

.step__accordion-block-left h3 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

:deep(.step__accordion-block-container) {
  display: flex;
  align-items: center;
  column-gap: 31px;
}

:deep(.step__accordion-block-units) {
  display: flex;
  align-items: center;
  column-gap: 46px;
}

:deep(.step__accordion-svg) {
  position: relative;
  transform: rotate(0);
  transition: 0.5s ease;
}

:deep(.step__accordion-item--active .step__accordion-svg) {
  transform: rotate(-180deg);
  transition: 0.5s ease;
}

:deep(.step__accordion-head) {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 15px 0;
  column-gap: 20px;
  /*  */
  cursor: pointer;
}

.step__accordion-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

:deep(.step__accordion-overlay) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}

.step__accordion-item--active.step__accordion-item--office
  :deep(.step__accordion-body) {
  border-top: none;
  padding-top: 10px;
}
/*
.step__accordion-item--active.step__accordion-item--office
  :deep(.step__accordion-wrapper) {
  display: grid;
  row-gap: 35px;
} */

.step__accordion-item--active :deep(.step__accordion-body) {
  display: block;
}

:deep(.step__accordion-body--general ul) {
  display: grid;
  /* row-gap: 10px; */
}

:deep(.edit-button) {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  padding-bottom: 1px;
  text-decoration: none;
  border-bottom: thin solid;
  margin-bottom: 2px;
}

:deep(.edit-button svg) {
  width: 12px;
  margin-left: 5px;
}

:deep(.edit-button a) {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

:deep(.delete-button) {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  padding-bottom: 1px;
  text-decoration: none;
  border-bottom: thin solid;
  margin-bottom: 2px;
}

:deep(.delete-button svg) {
  width: 12px;
  margin-left: 5px;
}

:deep(.delete-button a) {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
