import { IComponentConfig } from "@/app/@types/base-component";

import {
    createCutsHandleConfig,
    createCutsLockConfig,
    createCutsPatchesSetConfig,
} from "../../configs/cuts.config";

import { DoorCut } from "../../entities/door-cut";
import { CreateManyStrategy } from "../base/creation.strategy";
import {
    HandleTypeCode,
    LockTypeCode,
    PatchesSetTypeCode,
} from "@/app/constants/hardwares";

const SINGLE_SWING_DOOR_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().qty(0).typeCode(LockTypeCode).build(),
    createCutsHandleConfig().typeCode(HandleTypeCode).build(),
    createCutsPatchesSetConfig().typeCode(PatchesSetTypeCode).build(),
];

const DOUBLE_SWING_DOOR_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().typeCode(LockTypeCode).defaultQty(4).qty(4).build(),
    createCutsHandleConfig()
        .typeCode(HandleTypeCode)
        .defaultQty(4)
        .qty(4)
        .build(),
    createCutsPatchesSetConfig()
        .typeCode(PatchesSetTypeCode)
        .defaultQty(4)
        .qty(4)
        .build(),
];

const SINGLE_SLIDING_DOOR_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().typeCode(LockTypeCode).build(),
    createCutsHandleConfig().typeCode(HandleTypeCode).build(),
];

const DOUBLE_SLIDING_DOOR_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().typeCode(LockTypeCode).defaultQty(4).qty(4).build(),
    createCutsHandleConfig()
        .typeCode(HandleTypeCode)
        .defaultQty(4)
        .qty(4)
        .build(),
];

const KOMPAS_SINGLE_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().typeCode(LockTypeCode).build(),
    createCutsHandleConfig().typeCode(HandleTypeCode).build(),
];

const KOMPAS_DOUBLE_CUTS_CONFIG: IComponentConfig[] = [
    createCutsLockConfig().typeCode(LockTypeCode).defaultQty(4).qty(4).build(),
    createCutsHandleConfig()
        .typeCode(HandleTypeCode)
        .defaultQty(4)
        .qty(4)
        .build(),
];

export class SingleSwingDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(SINGLE_SWING_DOOR_CUTS_CONFIG, DoorCut);
    }
}

export class DoubleSwingDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(DOUBLE_SWING_DOOR_CUTS_CONFIG, DoorCut);
    }
}

export class SingleSlidingDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(SINGLE_SLIDING_DOOR_CUTS_CONFIG, DoorCut);
    }
}

export class DoubleSlidingDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(DOUBLE_SLIDING_DOOR_CUTS_CONFIG, DoorCut);
    }
}

export class KompasSingleDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(KOMPAS_SINGLE_CUTS_CONFIG, DoorCut);
    }
}

export class KompasDoubleDoorCutsStrategy extends CreateManyStrategy {
    constructor() {
        super(KOMPAS_DOUBLE_CUTS_CONFIG, DoorCut);
    }
}
