import { HardwareItem } from "@/models/structure";
import {
    BaseTransformer,
    getPriceFromCalculationItem,
    getSumFromCalculationItem,
} from "./base.transformers";
import { unwrapRef } from "@/utils/reactive";
import { Hardware, MountHardware } from "../entities/hardware";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

export const HardwareTransformer: BaseTransformer<HardwareItem, Hardware> = {
    toOld(hardware: Hardware) {
        return {
            code: unwrapRef(hardware.code) || "",
            hardwareTypeCode: unwrapRef(hardware.typeCode) || "",
            hardwareValueCode: unwrapRef(hardware.structureType) || "",
            type: unwrapRef(hardware.type) || "",
            qty: unwrapRef(hardware.qty) || 0,
            price: hardware.price.getSystemPrice(CostDealType).getValue() || 0,
            priceB2C:
                hardware.price.getSystemPrice(B2CDealType).getValue() || 0,
            priceB2B:
                hardware.price.getSystemPrice(B2BDealType).getValue() || 0,
            sum: hardware.sum.getSystemPrice(B2CDealType).getValue() || 0,
        };
    },
    fromOld(hardwareConfig: HardwareItem, calculations) {
        const price = getPriceFromCalculationItem(
            calculations,
            hardwareConfig.code,
            hardwareConfig.type || "",
        );

        const sum = getSumFromCalculationItem(
            calculations,
            hardwareConfig.code,
            hardwareConfig.type || "",
        );

        return new Hardware({
            code: hardwareConfig.code,
            typeCode: hardwareConfig.hardwareTypeCode,
            structureType: hardwareConfig.hardwareValueCode,
            qty: hardwareConfig.qty,
            price,
            sum,
        });
    },
};
