import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";

export class AluminiumOverdoorCalculationStrategy extends CalculationStrategy {
    calculate(glass: ReactiveComponent): PriceResult {
        if (!glass) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(glass, CostDealType),
            priceB2C: this.calculatePriceForType(glass, B2BDealType),
            priceB2B: this.calculatePriceForType(glass, B2CDealType),
        };
    }

    private calculatePriceForType(glass: ReactiveComponent, dealType: string) {
        const glassArea = glass.dimensions.area.toSquareFt().getValue();
        const price = glass.price.getSystemPrice(dealType).getValue();

        return price * glassArea;
    }
}
