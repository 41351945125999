import { OverdoorMatetialCreationStrategies } from "@/app/domain/strategies";

import { SheetMaterialCode } from "../constants";

import {
    GlassPartition,
    GlassThinknessPartition,
    OverdoorMaterial,
    Partition,
} from "../domain";

export class OverdoorService {
    static createOverdoorMaterial(
        overdoorMaterialType: string,
        activePartition: Partition,
    ): OverdoorMaterial[] {
        if (overdoorMaterialType === SheetMaterialCode) {
            const strategies = {
                [SheetMaterialCode]:
                    OverdoorMatetialCreationStrategies.OverdoorAluminiumMaterialStrategy,
            };

            const Strategy = new strategies[overdoorMaterialType]();
            return Strategy.create() as unknown as OverdoorMaterial[];
        }

        return this.createOverdoorGlassMaterial(activePartition);
    }

    static createOverdoorGlassMaterial(
        activePartition: Partition,
    ): OverdoorMaterial[] {
        const glass = activePartition.getActiveGlass();
        const glassThickness = activePartition.getActiveGlassThinkness();

        const overdoorGlass = new GlassPartition({
            structureType: glass!.structureType,
        });

        const overdoorGlassThickness = new GlassThinknessPartition({
            structureType: glassThickness!.structureType,
        });

        return [overdoorGlass, overdoorGlassThickness] as OverdoorMaterial[];
    }
}
