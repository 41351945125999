import { StructureItem } from "@/models/structure";
import { BaseTransformer, getCalculationItem } from "./base.transformers";
import { OutOfSquare } from "../entities/out-of-square";
import { Partition } from "../entities/partition";

export const OutOfSquareTransformer: BaseTransformer<
    Pick<StructureItem, "outOfSquareQty">,
    OutOfSquare
> = {
    toOld(partition: Partition) {
        const outOfSquare = partition.getActiveOutOfSquare();

        return {
            outOfSquareQty: outOfSquare?.qty || 0,
        };
    },
    fromOld(outOfSquareConfig: StructureItem, calculations) {
        const calculationItems = getCalculationItem(
            calculations,
            undefined,
            "outOfSquare",
        );

        return new OutOfSquare({
            qty: outOfSquareConfig.outOfSquareQty,
            price: {
                price: calculationItems?.price || 0,
                priceB2C: calculationItems?.priceB2C || 0,
                priceB2B: calculationItems?.priceB2B || 0,
            },
            sum: {
                price: calculationItems?.sum || 0,
                priceB2C: calculationItems?.sumB2C || 0,
                priceB2B: calculationItems?.sumB2B || 0,
            },
        });
    },
};
