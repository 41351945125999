import { BaseTransformer } from "./base.transformers";
import {
    EstimateInfo as OldEstimateInfo,
    Estimate as OldEstimate,
    ProgressInfo,
    DeliveryInfo,
} from "@/models/estimate";
import { Estimate as NewEstimate } from "../entities/estimate";
import { SpaceTransformer } from "./space.transformer";
import { Space } from "../entities/space";
import { Delivery, DeliveryItem } from "../entities/delivery";
import { DeliverItemsTransformer } from "./deliver-items.transformer";
import { DimensionValue, ReactiveDimensions } from "../entities/dimensions";
import { Pricing } from "../entities/pricing";
import { CalculationItem } from "@/models/calculate";

type EstimateTransformerToOldRequest = {
    estimate: NewEstimate;
    spaces: Space[];
    delivery: Delivery;
};

export const EstimateTransformer: BaseTransformer<
    OldEstimate,
    NewEstimate,
    EstimateTransformerToOldRequest
> = {
    toOld({ estimate, spaces, delivery }) {
        const deliveryItems = (delivery?.getDeliveryItems() ||
            []) as unknown as DeliveryItem[];

        const info: OldEstimateInfo = {
            started: Date.now(),
            priceDate: new Date(estimate.priceDate) || new Date(),
            priceDateFormated: estimate.priceDateFormated || "",
            subtotal:
                estimate.subtotal
                    ?.getSystemPrice(estimate.dealTypeCode)
                    .getValue() || 0,
            estimatePrice:
                estimate.estimatePrice
                    ?.getSystemPrice(estimate.dealTypeCode)
                    .getValue() || 0,
            code: estimate.code || "",
            estimateNo: estimate.estimateNo || "",
            trackCode: estimate.trackCode || "",
            title: estimate.title || "",
            client: estimate.client,
            managerCode: estimate.managerCode || "",
            estimatorCode: estimate.estimatorCode || "",
            dealTypeCode: estimate.dealTypeCode || "",
            installationPlaceCode: estimate.installationPlaceCode || "",
            installationUsageCode: estimate.installationUsageCode || "",
            installationPlaceTitle: null, // This field seems to be removed in new format
            installedSystemCode: estimate.installedSystemCode || "",
            usageCode: estimate.installationUsageCode || "",
            colorCode: estimate.colorCode || "",
            shippingAddress: estimate.client.shippingAddress || "",
            shippingZip: estimate.shippingZip || "",
            shippingState: estimate.shippingState || "",
            delivery: estimate.delivery || false,
            installation: estimate.installation || false,
            isHaflInstallation: estimate.isHalfInstallation || false,
            halfInstallation: estimate.halfInstallation || 0,
            halfInstallationWithDiscount:
                estimate.halfInstallationWithDiscount || 0,
            totalSquareFt: estimate.totalSquareFt?.toSquareFt().format() || "",
        };

        const progressInfo: ProgressInfo = {
            status: estimate.progress.status || "",
            updated: estimate.progress.updated || "",
            validatedPage: estimate.progress.validatedPage || 1,
            activePage: estimate.progress.activePage || 1,
            activeSpaceCode: estimate.progress.activeSpaceCode || "",
            activeStructureCode: estimate.progress.activeStructureCode || "",
            activeDoorCode: estimate.progress.activeDoorCode || "",
            activeFillerCode: estimate.progress.activeFillerCode || "",
            isSaved: estimate.progress.isSaved || false,
        };

        // Create delivery info with empty arrays since they are not present in new format
        const deliveryInfo: DeliveryInfo = {
            deliveryAddress: {
                state: estimate.shippingState || "",
                zip: estimate.shippingZip || "",
                address: estimate.client.shippingAddress || "",
            },
            deliveryCosts: deliveryItems.map(DeliverItemsTransformer.toOld),
            extraCosts: [],
            multiplicator: estimate.multiplicator || 1,
            discountPercent: estimate.discountPercent ?? 0,
            discountSum: estimate.discountSum ?? 0,
        };

        // Construct the final old format estimate
        return {
            overhead: [],
            spaces: spaces.map(SpaceTransformer.toOld),
            info,
            delivery: deliveryInfo,
            progress: progressInfo,
        };
    },
    fromOld(estimate: OldEstimate, calculations: CalculationItem): NewEstimate {
        const estimateInfo = estimate.info;
        const estimateProgress = estimate.progress;
        const deliveryInfo = estimate.delivery;

        return {
            title: estimateInfo.title || "",
            code: estimateInfo.code || "",
            started: estimateInfo.started || new Date(),
            priceDate: estimateInfo.priceDate || Date.now(),
            priceDateFormated: estimateInfo.priceDateFormated || "",
            dealTypeCode: estimateInfo.dealTypeCode || "",
            installationPlaceCode: estimateInfo.installationPlaceCode || "",
            installationUsageCode: estimateInfo.installationUsageCode || "",
            installedSystemCode: estimateInfo.installedSystemCode || "",
            trackCode: estimateInfo.trackCode || "",
            estimateNo: estimateInfo.estimateNo || "",
            colorCode: estimateInfo.colorCode || "",
            delivery: estimateInfo.delivery || false,
            installation: estimateInfo.installation || false,
            shippingZip: estimateInfo.shippingZip || "",
            shippingState: estimateInfo.shippingState || "",
            managerCode: estimateInfo.managerCode || "",
            estimatorCode: estimateInfo.estimatorCode || "",
            client: {
                name: estimateInfo.client.name || "",
                businessName: estimateInfo.client.businessName || "",
                email: estimateInfo.client.email || "",
                phone: estimateInfo.client.phone || "",
                shippingAddress: estimateInfo.client.billingAddress || "",
            },
            progress: {
                status: estimateProgress.status || "",
                updated: estimateProgress.updated || "",
                validatedPage: estimateProgress.validatedPage || 1,
                activePage: estimateProgress.activePage || 1,
                activeSpaceCode: estimateProgress.activeSpaceCode || "",
                activeStructureCode: estimateProgress.activeStructureCode || "",
                activeDoorCode: estimateProgress.activeDoorCode || "",
                activeFillerCode: estimateProgress.activeFillerCode || "",
                isSaved: estimateProgress.isSaved || false,
            },
            multiplicator: deliveryInfo.multiplicator,
            discountPercent: deliveryInfo.discountPercent,
            discountSum: deliveryInfo.discountSum,
            halfInstallation: estimateInfo.halfInstallation,
            halfInstallationWithDiscount:
                estimateInfo.halfInstallationWithDiscount,
            isHalfInstallation: estimateInfo.isHaflInstallation,
            totalSquareFt: new DimensionValue(0) || null,
            subtotal: new Pricing(estimateInfo.subtotal, 0, 0),
            estimatePrice: new Pricing(
                calculations.price,
                calculations.priceB2C,
                calculations.priceB2B,
            ),
        };
    },
};
