import "@/lib/axios";
import "./bootstrap";

import { createApp } from "vue";
import { createPinia } from "pinia";

import vSelect from "vue-select";
import Notifications from "@kyvg/vue3-notification";
import { createPersistedState } from "pinia-plugin-persistedstate";

import App from "./App.vue";
import router from "./router";
import { PiniaSerializerService } from "./app/services/pinia-serializer.service";

import "./assets/css/normalize.css";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import { usePiniaPersist } from "./lib/usePiniaPersist";

const app = createApp(App);
const pinia = createPinia();

pinia.use(usePiniaPersist);

// pinia.use(
//     createPersistedState({
//         debug: true,
//         auto: false,

//         serializer: {
//             serialize: PiniaSerializerService.serialize,
//             deserialize: PiniaSerializerService.deserialize,
//         },
//     }),
// );

app.component("v-select", vSelect);
app.use(pinia);
app.use(router);
app.use(Notifications);

app.mount("#app");
