<template>
  <div v-if="$route.params.id === '1'" class="step__block step__block--data">
    <div class="step__block-container">
      <span class="step__block-container-left">Estimate started:</span>
      <span>{{ formatDateTime(estimate.started) }}</span>
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Price Date:</span>
      <input type="date" v-model="estimate.priceDateFormated" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required">Deal type:</span>
      <DealTypeSelect v-model="estimate.dealTypeCode" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Installation place:</span>
      <InstallationPlaceSelect v-model="estimate.installationPlaceCode" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Usage:</span>
      <InstallationUsageSelect
        v-model="estimate.installationUsageCode"
        :installationPlaceCode="estimate.installationPlaceCode"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required">System:</span>
      <SystemSelect v-model="estimate.installedSystemCode" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required"
        >Finish (color):</span
      >
      <FinishColorSelect
        v-model="estimate.colorCode"
        :installedSystemCode="estimate.installedSystemCode"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required"
        >First and Last name (in full):</span
      >
      <ClientNameSelect v-model="estimate.client.name" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left"
        >Business name (if applicable):</span
      >
      <input
        type="text"
        v-model="estimate.client.businessName"
        placeholder="James BBB"
        autocomplete="new-password"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Email:</span>
      <input
        type="text"
        v-model="estimate.client.email"
        placeholder="example@gmail/com"
        autocomplete="new-password"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Phone:</span>
      <input
        type="text"
        v-model="estimate.client.phone"
        placeholder="+1 XXX-XXX-XXXX"
        autocomplete="new-password"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Shipping address:</span>
      <input
        type="text"
        v-model="estimate.client.shippingAddress"
        placeholder="NY, Boynton Avenue, 1145"
        autocomplete="new-password"
      />
    </div>
    <div class="step__block-container">
      <span>Delivery:</span>
      <RadioGroup name="delivery" v-model="estimate.delivery" />
    </div>
    <div class="step__block-container">
      <span>Installation:</span>
      <RadioGroup name="installation" v-model="estimate.installation" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Shipping ZIP:</span>
      <input
        type="text"
        v-model="estimate.shippingZip"
        placeholder=""
        autocomplete="new-password"
      />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required"
        >Shipping state:</span
      >
      <ShippingStatesSelect v-model="estimate.shippingState" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left label-required">Manager:</span>
      <ManagerSelect v-model="estimate.managerCode" />
    </div>
    <div class="step__block-container">
      <span class="step__block-container-left">Estimator:</span>
      <EstimatorSelect v-model="estimate.estimatorCode" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import dayjs from "dayjs";

import { useDictStore } from "@/stores/dictStore";
import { usePriceStore } from "@/stores/priceStore";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

import { formatDateTime } from "@/utils/common";

import {
  ClientNameSelect,
  DealTypeSelect,
  EstimatorSelect,
  FinishColorSelect,
  InstallationPlaceSelect,
  InstallationUsageSelect,
  ManagerSelect,
  ShippingStatesSelect,
  SystemSelect,
} from "@/modules/estimate";
import RadioGroup from "@/shared/ui/RadioGroup.vue";
import { ValidationService } from "@/app/services/validation.service";

defineExpose({ savePage, validatePage, renameEstimate });

const estimateStore = useNewEstimateStore();
const estimate = computed(() => estimateStore.estimate);

if (estimate.value.dealTypeCode === null) {
  usePriceStore().initCurrentPriceRule();
}

function savePage() {
  estimateStore.renameEstimate();
}

function validatePage() {
  return ValidationService.validateEstimate(estimate.value);
}

// TODO: move to new estimate store
function renameEstimate(value: string) {
  estimate.value.title = value;
}

watch(
  () => estimate.value.priceDateFormated,
  (priceDateFormated) => {
    const date = dayjs(priceDateFormated, "YYYY-MM-DD").toDate();
    estimate.value.priceDate = date.getTime();
    useDictStore().loadAllDictsByDate(priceDateFormated);
  },
);
</script>

<style scoped>
.step__block {
  border-bottom: 1px solid #d2d8e3;
  padding-bottom: 20px;
}

.step__block--data,
.step__block-wrapper {
  padding-right: 80px;
  display: grid;
  row-gap: 10px;
}

.step__block-container {
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px;
}

.step__block-container-left {
  align-self: center;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
  padding: 9px 20px;
  background-color: #fff;
  border-radius: 25px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
