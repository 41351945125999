import { Structure } from "@/models/structure";
import { BaseTransformer, getCalculationItem } from "./base.transformers";
import { Space } from "../entities/space";
import { Partition } from "../entities/partition";
import { EstimatedSpace } from "@/models/estimate";
import { unwrapRef } from "@/utils/reactive";
import { PartitionTransformer } from "./partition.transformer";
import { DoorTransformer } from "./door.transformer";
import { Door } from "../entities/door";
import { Overdoor } from "../entities/overdoor";
import { Mounts } from "../entities/mounts";
import { OverdoorTransformer } from "./overdoor.transformer";
import { MountsTransformer } from "./mounts.transform";
import { CalculationItem } from "@/models/calculate";

export const SpaceTransformer: BaseTransformer<EstimatedSpace, Space[]> = {
    toOld(space: Space) {
        const partition = space.getAllPartitions() as unknown as Partition[];

        return {
            code: unwrapRef(space.code),
            title: unwrapRef(space.title),
            structures: partition.map((partition) => {
                const doors = partition.getAllDoors() as unknown as Door[];
                const fillers =
                    partition.getAllOverdoors() as unknown as Overdoor[];
                const mounts = partition.getActiveMount() as unknown as Mounts;

                return {
                    code: unwrapRef(partition.code),
                    partition: PartitionTransformer.toOld(partition),
                    doors: doors.map(DoorTransformer.toOld),
                    fillers: fillers.map(OverdoorTransformer.toOld), // transom and fillers
                    mounts: MountsTransformer.toOld(mounts), // CHANEL, TRACKS, PROFILES
                    additionalCosts: [],
                };
            }),
        };
    },

    fromOld(
        spaces: EstimatedSpace[],
        calculations: CalculationItem[],
    ): Space[] {
        return spaces.map((spaceConfig) => {
            const space = new Space({
                code: spaceConfig.code,
                title: spaceConfig.title,
            });

            const spaceCalculations = getCalculationItem(
                calculations,
                spaceConfig.code,
                "space",
            );

            const partitions = spaceConfig.structures.map((partition) =>
                PartitionTransformer.fromOld(
                    partition,
                    spaceCalculations?.subitems.items || [],
                ),
            );

            space.addManyComponents(partitions);

            return space;
        });
    },
};
