import { IComponentConfig } from "@/app/@types/base-component";
import {
    CutsHolesTypeCode,
    HandleTitle,
    LockTitle,
    PatchesSetTitle,
} from "@/app/constants/hardwares";

export type ConfigBuilder = {
    title(value: string): ConfigBuilder;
    qty(value: number): ConfigBuilder;
    defaultQty(value: number): ConfigBuilder;
    typeCode(value: string): ConfigBuilder;
    build(): IComponentConfig;
};

export const createCutsLockConfig = () => {
    return createCutsConfig().title(LockTitle);
};

export const createCutsHandleConfig = () => {
    return createCutsConfig().title(HandleTitle);
};

export const createCutsPatchesSetConfig = () => {
    return createCutsConfig().title(PatchesSetTitle);
};

const createCutsConfig = (): ConfigBuilder => {
    const config = {
        title: "",
        structureType: CutsHolesTypeCode,
        typeCode: "",
        qty: 2,
        defaultQty: 2,
    };

    const builder = {
        title(value: string) {
            config.title = value;
            return builder;
        },
        qty(value: number) {
            config.qty = value;
            return builder;
        },
        defaultQty(value: number) {
            config.defaultQty = value;
            return builder;
        },
        typeCode(value: string) {
            config.typeCode = value;
            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};
