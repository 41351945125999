import { CalculationItem } from "@/models/calculate";
import { Estimate as NewEstimate } from "../entities/estimate";
import { Space } from "../entities/space";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { ReactiveComponent } from "../entities/base-component";
import { unwrapRef } from "@/utils/reactive";

export const CalculationTransformer = {
    toOld(estimate: NewEstimate, spaces: Space[]): CalculationItem {
        const estimatePrice = estimate.estimatePrice;

        return {
            elementType: "estimate",
            elementCode: estimate.code,
            qty: 1,
            multiplier: estimate.multiplicator,
            discountSum: estimate.discountPercent,
            discountPercent: estimate.discountPercent,
            price: estimatePrice.getSystemPrice(CostDealType).getValue(),
            priceB2C: estimatePrice.getSystemPrice(B2CDealType).getValue(),
            priceB2B: estimatePrice.getSystemPrice(B2BDealType).getValue(),
            subitems: {
                items: spaces.map((space) =>
                    convertStructureToOldCaclulationItem(estimate, space),
                ),
            },
        };
    },
};

const convertStructureToOldCaclulationItem = (
    estimate: NewEstimate,
    structure: ReactiveComponent,
): CalculationItem => {
    return {
        elementCode: unwrapRef(structure.code),
        elementType: unwrapRef(structure.type) || "",
        elementParams: {},
        subitems: {
            items: structure.components.map((component) =>
                convertStructureToOldCaclulationItem(estimate, component),
            ),
        },
        price: structure.price.getSystemPrice(CostDealType).getValue(),
        priceB2C: structure.price.getSystemPrice(B2CDealType).getValue(),
        priceB2B: structure.price.getSystemPrice(B2BDealType).getValue(),
        qty: unwrapRef(structure.qty),
        qtyDisplay: unwrapRef(structure.qty),
        sum: structure.sum.getSystemPrice(CostDealType).getValue(),
        sumB2C: structure.sum.getSystemPrice(B2CDealType).getValue(),
        sumB2B: structure.sum.getSystemPrice(B2BDealType).getValue(),
        subSum: estimate.subtotal
            .getSystemPrice(estimate.dealTypeCode)
            .getValue(),
        sumOrig: estimate.subtotal
            .getSystemPrice(estimate.dealTypeCode)
            .getValue(),
        multiplier: 0,
        discountSum: 0,
        discountPercent: 0,
    };
};
