<template>
  <div class="step__block-wrapper">
    <div>
      <span>1/2 of the installation in the product price</span>
    </div>
    <div class="step__block-wrapper-right">
      <CheckboxField v-model="estimate.isHalfInstallation" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { CheckboxField } from "@/shared";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useDeliveryStore } from "@/stores/new/delivery.store";
import { DeliveryService } from "@/app/services/delivery.service";

const estimateStore = useNewEstimateStore();
const deliveryStore = useDeliveryStore();

const estimate = computed(() => estimateStore.estimate);
const isHalfInstallation = computed({
  get: () => estimate.value.isHalfInstallation,
  set: (value) => {
    estimate.value.isHalfInstallation = value;
  },
});
const discountPercent = computed(() => estimateStore.estimate.discountPercent);

const installation = computed(
  () => deliveryStore.activeDelivery?.getInstallation(),
);

const installationSum = computed(() => installation.value?.qty || 0);

watch(
  () => [
    isHalfInstallation.value,
    installationSum.value,
    discountPercent.value,
  ],
  () => {
    const discount = 100 - discountPercent.value;

    if (estimate.value.isHalfInstallation) {
      const { withDiscount, withoutDiscount } =
        DeliveryService.calculateHalfInstallation(
          installationSum.value,
          discount,
        );

      estimate.value.halfInstallation = withoutDiscount;
      estimate.value.halfInstallationWithDiscount = withDiscount;
    } else {
      estimate.value.halfInstallation = 0;
      estimate.value.halfInstallationWithDiscount = 0;
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.step__block-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin-top: 8px;
}

.step__block-wrapper-right {
  display: flex;
  align-items: center;
  column-gap: 12px;

  margin-right: 12px;
}

.step__block-wrapper-right input {
  width: 100px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 17px;
}
</style>
