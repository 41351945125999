<template>
  <DimensionsTable v-model="activePartition!.dimensions" :immediate="false" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import { DimensionsTable } from "@/shared";

import { usePartitionStore } from "@/stores/new/partition.store";

const partitionsStore = usePartitionStore();
const activePartition = computed(() => partitionsStore.activePartitions);
</script>
