<template>
  <div
    class="table__block-item"
    :class="['item-' + estimate.header.estimateN]"
    :key="estimate.header.code"
  >
    <input
      class="est-num-inp"
      :id="'est-num-inp' + estimate.header.code"
      v-model="estimateNumber"
      type="text"
      @click="isInputShown = true"
      @blur="isInputShown = false"
      ref="inputRef"
    />
    <button
      v-show="isInputShown"
      type="button"
      class="est-num-btn"
      :id="'est-num-btn' + estimate.header.code"
      @click="saveEstNumb"
      @mousedown.prevent
    >
      Save
    </button>
  </div>
</template>

<script setup lang="ts">
import { postEstimate } from "@/utils/api";
import { defineProps, onMounted, onUnmounted, ref } from "vue";

type EstimateNumberColumnProps = {
  estimate: any;
};

const props = defineProps<EstimateNumberColumnProps>();

const estimateNumber = ref(props.estimate.header.estimateN);
const isInputShown = ref(false);

const inputRef = ref<HTMLInputElement | null>(null);

const saveEstNumb = async () => {
  const updatedEstimate = {
    ...props.estimate,
    header: {
      ...props.estimate.header,
      estimateN: estimateNumber.value,
    },
    info: {
      ...props.estimate.info,
      estimateNo: estimateNumber.value,
    },
  };

  inputRef.value?.blur();

  try {
    await postEstimate(
      updatedEstimate,
      updatedEstimate.estimate.info.code,
      false,
    );
    isInputShown.value = false;
  } catch (error) {
    console.error(error);
  }
};

const onEscPressed = (e: KeyboardEvent) => {
  if (e.key === "Escape" || e.key === "Esc") {
    inputRef.value?.blur();
  }
};

onMounted(() => {
  document.addEventListener("keydown", onEscPressed);
});

onUnmounted(() => {
  document.removeEventListener("keydown", onEscPressed);
});
</script>

<style lang="scss" scoped>
.table__block-item {
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.est-num-btn {
  border: 1px solid #474749;
  border-radius: 5px;
}
.est-num-inp:focus {
  border: 1px solid #474749;
  border-radius: 5px;
  margin-bottom: 3px;
}
</style>
