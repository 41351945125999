<template>
  <div v-if="header.type === 'header'" class="step__accordion-block-head">
    <div class="step__accordion-block-container">
      <h3>{{ header.title !== "" ? header.title : header.objectType }}</h3>
      <div class="edit-button" v-if="header.objectType === 'partition'">
        <a @click="emit('headerClickEdit', header.objectCode)"
          >edit
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 1.62132C11.8978 1.2235 12.4374 1 13 1C13.5626 1 14.1022 1.2235 14.5 1.62132C14.8978 2.01915 15.1213 2.55871 15.1213 3.12132C15.1213 3.68393 14.8978 4.2235 14.5 4.62132L5 14.1213L1 15.1213L2 11.1213L11.5 1.62132Z"
              stroke="#9FB7CD"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <div class="delete-button">
        <a
          v-if="canDelete"
          @click="emit('headerClickDelete', header.objectCode)"
          >delete
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="16"
            height="16"
            viewBox="0,0,256,256"
          >
            <g
              fill="#ffffff"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <g transform="scale(10.66667,10.66667)">
                <path
                  d="M4.70703,3.29297l-1.41406,1.41406l7.29297,7.29297l-7.29297,7.29297l1.41406,1.41406l7.29297,-7.29297l7.29297,7.29297l1.41406,-1.41406l-7.29297,-7.29297l7.29297,-7.29297l-1.41406,-1.41406l-7.29297,7.29297z"
                ></path>
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
    <div class="step__accordion-table-header">
      <div class="step__accordion-table-header__cell">
        {{ header.qtyTitle }}
      </div>
      <div class="step__accordion-table-header__cell">
        {{ header.priceTitle }}
      </div>
      <div class="step__accordion-table-header__cell">
        {{ header.sumTitle }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { SummaryHeader } from "@/models/summary";

const props = defineProps({
  summaryHeader: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);

const header = <SummaryHeader>props.summaryHeader;
const canDelete = computed(() => header.objectType === "partition");
</script>

<style lang="scss" scoped>
h3::first-letter {
  text-transform: capitalize;
}

.step__accordion-block-head {
  display: grid;
  grid-template-columns: 65% 35%;

  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 20px;
}

.step__accordion-block-head h3 {
  text-transform: uppercase;
}

.step__accordion-table-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;

  &__cell {
    text-align: right;
  }
}
</style>
