<template>
  <StepTotalPrice title="Page total" :price="activeOverhead?.sum" />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import StepTotalPrice from "@/shared/ui/StepTotalPrice.vue";

import { useOverheadStore } from "@/stores/new/overhead.store";
import { PricingService } from "@/app/services/pricing.service";

const overheadStore = useOverheadStore();
const activeOverhead = computed(() => overheadStore.activeOverhead);

const pricingService = new PricingService();

watch(
  () => activeOverhead.value?.components,
  () => {
    const sum = pricingService.calculateOverheadTotalPrice();

    activeOverhead.value?.update({
      sum,
    });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped></style>
