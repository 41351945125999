import { StructureItem } from "@/models/structure";
import {
    BaseTransformer,
    getPriceFromCalculationItem,
    getSumFromCalculationItem,
} from "./base.transformers";
import { Partition } from "../entities/partition";
import { unwrapRef } from "@/utils/reactive";
import { CalculationItem } from "@/models/calculate";
import {
    GlassPartition,
    GlassThinknessPartition,
} from "../entities/glass-partition";

type BaseGlassOldResponse = Pick<
    StructureItem,
    "glassSortCode" | "glassThicknessCode"
>;

interface BaseGlassTransformer<TNewResponse = GlassPartition>
    extends BaseTransformer<BaseGlassOldResponse, TNewResponse> {}

export const GlassTransformer: BaseGlassTransformer = {
    toOld(component: any) {
        const glass = component.getActiveGlass();
        const glassThickness = component.getActiveGlassThinkness();

        return {
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
        };
    },
    fromOld(component: StructureItem, calculations: CalculationItem[]) {
        const price = getPriceFromCalculationItem(
            calculations,
            "glassPartition",
        );
        const sum = getSumFromCalculationItem(calculations, "glassPartition");

        const glassPartition = new GlassPartition({
            structureType: component.glassSortCode,
            price,
            sum,
        });

        const glassThinkness = new GlassThinknessPartition({
            structureType: component.glassThicknessCode,
        });

        glassPartition.addComponent(glassThinkness);

        return glassPartition;
    },
};
