import { computed } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { usePartitionStore } from "@/stores/new/partition.store";
import { useDoorsStore } from "@/stores/new/doors.store";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useSpacesStore } from "@/stores/new/spaces.store";
import { useOverheadStore } from "@/stores/new/overhead.store";
import { useMountsStore } from "@/stores/new/mounts.store";
import { useExtraStore } from "@/stores/new/extra.store";

import { DoorCut, ExtraItem, Pricing, ReactiveComponent } from "@/app/domain";
import {
    ICalculationStrategy,
    GlassCalculationStrategy,
    GlassPricingStrategy,
    OutOfSquareCalculationStrategy,
    OutOfSquarePricingStrategy,
    IPricingStrategy,
    PriceResult,
    GlassDoorPricingStrategy,
    GlassDoorCalculationStrategy,
    DoorHardwarePricingStrategy,
    DoorHardwareCalculationStrategy,
    DoorCutsPricingStrategy,
    DoorCalculationStrategy,
    DoorPricingStrategy,
    GlassOverdoorPricingStrategy,
    GlassOverdoorCalculationStrategy,
    OverdoorAssetsCalculationStrategy,
    OverdoorAssetsPricingStrategy,
    OverdoorTotalPriceCalculationStrategy,
    OverdoorWithoutAssetsPriceCalculationStrategy,
    MountHardwarePricingStrategy,
    MountHardwareCalculationStrategy,
    MountCalculationStrategy,
    OverheadPricingStrategy,
    OverheadCalculationStrategy,
    OverheadTotalCalculationStrategy,
    ExtraPricingStrategy,
    ExtraCalculationStrategy,
    DiscountSumCalculationStrategy,
    DiscountPercentCalculationStrategy,
    EstimateCalculationStrategy,
    DeliveryPricingStrategy,
    DeliveryCalculationStrategy,
    DoorCutsCalculationStrategy,
} from "@/app/domain/strategies";

import { PartitionPricingStrategy } from "../domain/strategies/pricing/partition-pricing.strategy";
import { PartitionCalculationStrategy } from "../domain/strategies/calucation/partition-calculation.strategy";
import { Hardware, MountHardware } from "../domain/entities/hardware";
import { AluminiumOverdoorCalculationStrategy } from "../domain/strategies/calucation/aluminium-overdoor-calculation.strategy";
import { AluminiumOverdoorPricingStrategy } from "../domain/strategies/pricing/aluminium-overdoor-pricing.strategy";
import { OverheadItem } from "../domain/entities/overhead";
import { ExtraTotalCalculationStrategy } from "../domain/strategies/calucation/extra-calculation.strategy";
import { SpaceCalculationStrategy } from "../domain/strategies/calucation/space-calculation.strategy";
import { SubtotalCalculationStrategy } from "../domain/strategies/calucation/subtotal-calculation.strategy";
import { DeliveryItem } from "../domain/entities/delivery";
import { OverdoorPricingStrategy } from "../domain/strategies/pricing/overdoor-pricing.strategy";

type Constructor<T> = new (...args: unknown[]) => T;

export class PricingService {
    private estimateStore = useNewEstimateStore();
    private partitionStore = usePartitionStore();
    private doorsStore = useDoorsStore();
    private overdoorStore = useOverdoorStore();
    private mountsStore = useMountsStore();
    private overheadStore = useOverheadStore();
    private extraStore = useExtraStore();
    private spacesStore = useSpacesStore();

    public getPartitionGlassPrices() {
        const installedSystemCode = computed(
            () => this.estimateStore.estimate.installedSystemCode,
        );

        const stateCode = computed(
            () => this.estimateStore.estimate.shippingState,
        );

        const activePartition = computed(
            () => this.partitionStore.activePartitions,
        );

        return this.getPricesWithStrategy(
            GlassPricingStrategy,
            installedSystemCode.value,
            stateCode.value,
            activePartition.value,
        );
    }

    public calculatePartitionGlassPrice() {
        const activePartition = computed(
            () => this.partitionStore.activePartitions,
        );
        const glass = activePartition.value?.getActiveGlass();

        return this.calculatePriceWithStrategy(GlassCalculationStrategy, glass);
    }

    public getDoorGlassPrices() {
        const installedSystemCode = computed(
            () => this.estimateStore.estimate.installedSystemCode,
        );
        const stateCode = computed(
            () => this.estimateStore.estimate.shippingState,
        );
        const activeGlassDoor = computed(() => this.doorsStore.activeGlassDoor);
        const activeGlassThinknessDoor = computed(
            () => this.doorsStore.activeGlassThinknessDoor,
        );

        return this.getPricesWithStrategy(
            GlassDoorPricingStrategy,
            installedSystemCode.value,
            stateCode.value,
            activeGlassDoor.value?.structureType,
            activeGlassThinknessDoor.value?.structureType,
        );
    }

    public calculateDoorGlassPrice() {
        const activeGlassDoor = computed(() => this.doorsStore.activeGlassDoor);

        return this.calculatePriceWithStrategy(
            GlassDoorCalculationStrategy,
            activeGlassDoor.value!,
        );
    }

    public calculateOutOfSquarePrice() {
        const activeOutOfSquare = computed(
            () => this.partitionStore.activeOutOfSquare,
        );

        return this.calculatePriceWithStrategy(
            OutOfSquareCalculationStrategy,
            activeOutOfSquare.value!,
        );
    }

    public getOverdoorAssetsPrices() {
        return this.getPricesWithStrategy(OverdoorAssetsPricingStrategy);
    }

    public calculateOverdoorAssetsPrice() {
        const activeOverdoorAssets = computed(
            () => this.overdoorStore.activeOverdoorAssets,
        );

        return this.calculatePriceWithStrategy(
            OverdoorAssetsCalculationStrategy,
            activeOverdoorAssets.value!,
        );
    }

    public getOutOfSquarePrices() {
        return this.getPricesWithStrategy(OutOfSquarePricingStrategy);
    }

    public calculateOverdoorOutOfSquarePrice() {
        const activeOutOfSquare = computed(
            () => this.overdoorStore.activeOverdoorOutOfSquare,
        );

        return this.calculatePriceWithStrategy(
            OutOfSquareCalculationStrategy,
            activeOutOfSquare.value!,
        );
    }

    public calculatePartitionPrice() {
        const activePartition = computed(
            () => this.partitionStore.activePartitions,
        );

        return this.calculatePriceWithStrategy(
            PartitionCalculationStrategy,
            activePartition.value!,
        );
    }

    public getPartitionPrices() {
        const activePartition = computed(
            () => this.partitionStore.activePartitions,
        );

        return this.getPricesWithStrategy(
            PartitionPricingStrategy,
            activePartition.value,
        );
    }

    public getHarwarePrices(hardware: Hardware) {
        const hardwareTypeCode = hardware.typeCode;
        const hardwareStructureType = hardware.structureType;
        return this.getPricesWithStrategy(
            DoorHardwarePricingStrategy,
            hardwareTypeCode,
            hardwareStructureType,
        );
    }

    public calculateDoorHardwarePrice(hardware: Hardware) {
        return this.calculatePriceWithStrategy(
            DoorHardwareCalculationStrategy,
            hardware,
        );
    }

    public getMountHarwarePrices(hardware: MountHardware) {
        const installedSystemCode = computed(
            () => this.estimateStore.estimate.installedSystemCode,
        );
        const hardwareTypeCode = hardware.typeCode;
        const hardwareStructureType = hardware.structureType;

        return this.getPricesWithStrategy(
            MountHardwarePricingStrategy,
            installedSystemCode,
            hardwareTypeCode,
            hardwareStructureType,
        );
    }

    public calculateMountHardwarePrice(hardware: Hardware) {
        return this.calculatePriceWithStrategy(
            MountHardwareCalculationStrategy,
            hardware,
        );
    }

    public calculateMountPrice() {
        const activeMount = computed(() => this.mountsStore.activeMount);

        return this.calculatePriceWithStrategy(
            MountCalculationStrategy,
            activeMount.value!,
        );
    }

    public getDoorCutsPrices() {
        return this.getPricesWithStrategy(DoorCutsPricingStrategy);
    }

    public calculateDoorCutsPrice(cut: DoorCut) {
        return this.calculatePriceWithStrategy(
            DoorCutsCalculationStrategy,
            cut,
        );
    }

    public calculateDoorPrice() {
        const activeDoor = computed(() => this.doorsStore.activeDoor);

        return this.calculatePriceWithStrategy(
            DoorCalculationStrategy,
            activeDoor.value!,
        );
    }

    public getDoorPrices() {
        const activeDoor = computed(() => this.doorsStore.activeDoor);

        return this.getPricesWithStrategy(
            DoorPricingStrategy,
            activeDoor.value!,
        );
    }

    public getOverdoorGlassPrices() {
        const installedSystemCode = computed(
            () => this.estimateStore.estimate.installedSystemCode,
        );
        const stateCode = computed(
            () => this.estimateStore.estimate.shippingState,
        );
        const activeGlassOverdoor = computed(() =>
            this.overdoorStore.activeOverdoorMaterials.at(0),
        );
        const activeGlassThinknessOverdoor = computed(() =>
            this.overdoorStore.activeOverdoorMaterials.at(1),
        );

        return this.getPricesWithStrategy(
            GlassOverdoorPricingStrategy,
            installedSystemCode.value,
            stateCode.value,
            activeGlassOverdoor.value?.structureType,
            activeGlassThinknessOverdoor.value?.structureType,
        );
    }

    public calculateOverdoorGlassPrice() {
        const activeGlassOverdoor = computed(
            () => this.overdoorStore.activeGlassOverdoor,
        );

        return this.calculatePriceWithStrategy(
            GlassOverdoorCalculationStrategy,
            activeGlassOverdoor.value!,
        );
    }

    public getOverdoorAluminiumPrices() {
        const activeAluminium = computed(() =>
            this.overdoorStore.activeOverdoorMaterials.at(0),
        );

        return this.getPricesWithStrategy(
            AluminiumOverdoorPricingStrategy,
            activeAluminium.value?.structureType,
        );
    }

    public calculateOverdoorAluminiumPrice() {
        const activeAluminiumMaterial = computed(() =>
            this.overdoorStore.activeOverdoorMaterials.at(0),
        );

        return this.calculatePriceWithStrategy(
            AluminiumOverdoorCalculationStrategy,
            activeAluminiumMaterial.value!,
        );
    }

    public getOverdoorPrice() {
        const activeOverdoor = computed(
            () => this.overdoorStore.activeOverdoor,
        );

        return this.getPricesWithStrategy(
            OverdoorPricingStrategy,
            activeOverdoor.value!,
        );
    }

    public calculateOverdoorTotalPrice() {
        const activeOverdoor = computed(
            () => this.overdoorStore.activeOverdoor,
        );

        return this.calculatePriceWithStrategy(
            OverdoorTotalPriceCalculationStrategy,
            activeOverdoor.value!,
        );
    }

    public calculateOverdoorWithoutAssetsPrice() {
        const activeOverdoor = computed(
            () => this.overdoorStore.activeOverdoor,
        );

        return this.calculatePriceWithStrategy(
            OverdoorWithoutAssetsPriceCalculationStrategy,
            activeOverdoor.value!,
        );
    }

    public getOverheadPrices(overheadItem: OverheadItem) {
        return this.getPricesWithStrategy(
            OverheadPricingStrategy,
            overheadItem.typeCode,
            overheadItem.structureType,
        );
    }

    public calculateOverheadPrice(overheadItem: OverheadItem) {
        return this.calculatePriceWithStrategy(
            OverheadCalculationStrategy,
            overheadItem!,
        );
    }

    public calculateOverheadTotalPrice() {
        const activeOverhead = computed(
            () => this.overheadStore.activeOverhead,
        );

        return this.calculatePriceWithStrategy(
            OverheadTotalCalculationStrategy,
            activeOverhead.value!,
        );
    }

    public getExtraPrices(extraItem: ExtraItem) {
        return this.getPricesWithStrategy(
            ExtraPricingStrategy,
            extraItem.structureType,
        );
    }

    public calculateExtraPrice(extraItem: ExtraItem) {
        return this.calculatePriceWithStrategy(
            ExtraCalculationStrategy,
            extraItem!,
        );
    }

    public getDeliveryPrices(deliveryItem: DeliveryItem) {
        return this.getPricesWithStrategy(
            DeliveryPricingStrategy,
            deliveryItem.typeCode,
            deliveryItem.structureType,
        );
    }

    public calculateDeliveryPrice(deliveryItem: DeliveryItem) {
        const isHalfInstallation = computed(
            () => this.estimateStore.estimate.isHalfInstallation,
        );

        return this.calculatePriceWithStrategy(
            DeliveryCalculationStrategy,
            deliveryItem!,
            isHalfInstallation,
        );
    }

    public calculateExtraTotalPrice() {
        const activeExtra = computed(() => this.extraStore.activeExtra);

        return this.calculatePriceWithStrategy(
            ExtraTotalCalculationStrategy,
            activeExtra.value!,
        );
    }

    public calculateSpacePrice() {
        const activeSpace = computed(() => this.spacesStore.activeSpace);

        return this.calculatePriceWithStrategy(
            SpaceCalculationStrategy,
            activeSpace.value!,
        );
    }

    public calculateSubtotalPrice() {
        const spaces = computed(() => this.spacesStore.spaces);
        const multiplicator = computed(
            () => this.estimateStore.estimate.multiplicator,
        );
        const halfInstallationWithDiscount = computed(
            () => this.estimateStore.estimate.halfInstallationWithDiscount,
        );

        return this.calculatePriceWithStrategy(
            SubtotalCalculationStrategy,
            spaces.value,
            multiplicator.value as number,
            halfInstallationWithDiscount.value as number,
        );
    }

    public calculateDiscountSum() {
        const subtotal = computed(() => this.estimateStore.estimate.subtotal);
        const discoutPercent = computed(
            () => this.estimateStore.estimate.discountPercent,
        );
        const dealType = computed(
            () => this.estimateStore.estimate.dealTypeCode,
        );

        const prices = this.calculatePriceWithStrategy(
            DiscountSumCalculationStrategy,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // component for this strategy not needed
            null,
            discoutPercent.value,
            subtotal.value,
        );

        const pricing = new Pricing(
            prices.price,
            prices.priceB2C,
            prices.priceB2B,
        );

        return pricing.getSystemPrice(dealType.value).getValue();
    }

    public calculateDiscountPercent() {
        const subtotal = computed(() => this.estimateStore.estimate.subtotal);
        const discountSum = computed(
            () => this.estimateStore.estimate.discountSum,
        );
        const dealType = computed(
            () => this.estimateStore.estimate.dealTypeCode,
        );

        const prices = this.calculatePriceWithStrategy(
            DiscountPercentCalculationStrategy,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // component for this strategy not needed
            null,
            discountSum.value,
            subtotal.value,
        );

        const pricing = new Pricing(
            prices.price,
            prices.priceB2C,
            prices.priceB2B,
        );

        return pricing.getSystemPrice(dealType.value).getValue();
    }

    public calculateEstimatePrice() {
        const subtotal = computed(() => this.estimateStore.estimate.subtotal);
        const discountSum = computed(
            () => this.estimateStore.estimate.discountSum,
        );

        return this.calculatePriceWithStrategy(
            EstimateCalculationStrategy,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // component for this strategy not needed
            null,
            discountSum.value,
            subtotal.value,
        );
    }

    private getPricesWithStrategy<T extends IPricingStrategy>(
        Strategy: Constructor<T>,
        ...args: unknown[]
    ): PriceResult {
        const strategy = new Strategy();
        return strategy.getPrices(...args);
    }

    private calculatePriceWithStrategy<T extends ICalculationStrategy>(
        Strategy: Constructor<T>,
        component: ReactiveComponent | ReactiveComponent[],
        ...args: any[]
    ): PriceResult {
        const strategy = new Strategy();
        return strategy.calculate(component, args);
    }
}
