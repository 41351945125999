<template>
  <template v-if="isDisplayedBlock">
    <template v-if="isBlockWithAccordion">
      <div class="step__accordion-body step__accordion-body--general">
        <template v-if="isHaveSubBlocks">
          <div
            v-for="subblock of block.subBlocks"
            :key="subblock.code"
            class="step__accordion-wrapper"
          >
            <SummaryBlock
              :summaryBlock="subblock"
              @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
              @header-click-delete="
                (...args) => emit('headerClickDelete', ...args)
              "
            />
          </div>
        </template>
        <SummaryHeader
          v-if="isHaveLines && block.header"
          :summaryHeader="block.header"
          @edit-click="emit"
        />
        <ul
          v-if="isHaveLines"
          :class="{ 'step__accordion-block-list': block.type !== 'info' }"
        >
          <SummaryLine
            v-for="line in block.lines"
            :key="line.code"
            :summaryLine="line"
          />
        </ul>
      </div>
    </template>

    <template v-if="!isBlockWithAccordion">
      <div class="step__accordion-block">
        <SummaryHeader
          v-if="isHaveLines && block.header"
          :summaryHeader="block.header"
          @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
          @header-click-delete="(...args) => emit('headerClickDelete', ...args)"
        />
        <ul v-if="isHaveLines" class="step__accordion-block-list">
          <SummaryLine
            v-for="line in block.lines"
            :key="line.code"
            :summaryLine="line"
          />
        </ul>
      </div>
      <template v-if="isHaveSubBlocks">
        <template v-for="subblock of block.subBlocks" :key="subblock.code">
          <SummaryBlock
            :summaryBlock="subblock"
            @header-click-edit="(...args) => emit('headerClickEdit', ...args)"
            @header-click-delete="
              (...args) => emit('headerClickDelete', ...args)
            "
          />
        </template>
      </template>
    </template>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";

import SummaryHeader from "./SummaryHeader.vue";
import SummaryLine from "./SummaryLine.vue";

import { SummaryBlock as SummaryBlockType } from "@/models/summary";

type SummaryBlockProps = {
  summaryBlock: SummaryBlockType;
};

const props = defineProps<SummaryBlockProps>();

const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);
const block = computed(() => props.summaryBlock);

const isBlockWithAccordion = computed(() => block.value.collapse);
const isHaveSubBlocks = computed(() => block.value.subBlocks?.length > 0);
const isHaveLines = computed(() => block.value.lines?.length > 0);
const isDisplayedBlock = computed(() => !!block.value);
</script>
