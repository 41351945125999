import {
    DividerPartitionCode,
    DoorPartitionCode,
    GlassPartitionCode,
    MultiFoldingPartitionCode,
} from "@/app/constants";
import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { Door } from "./door";
import { Overdoor } from "./overdoor";
import { Mounts } from "./mounts";
import { DimensionValue } from "./dimensions";
import { PricingService } from "@/app/services/pricing.service";
import { GlassPartition } from "./glass-partition";

export class Partition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "partition" });
    }

    substractDoorIfExistAndUpdateGlassArea = (
        doorGlass: GlassPartition | null,
    ): void => {
        const pricingService = new PricingService();

        if (!doorGlass) return;

        const doorGlassDimensions = doorGlass.dimensions.copy();
        const partitionDimensions = this.dimensions.copy();

        const partitionArea = partitionDimensions.calculateArea().getValue();
        const doorGlassArea = doorGlassDimensions.area.toInches().getValue();

        console.log("doorArea", doorGlassArea);

        const newPartitionArea = partitionArea - doorGlassArea;

        const glass = this.getActiveGlass() as unknown as GlassPartition;

        glass!.dimensions.area = new DimensionValue(newPartitionArea);

        const glassSum = pricingService.calculatePartitionGlassPrice();
        glass!.update({ sum: glassSum });
    };

    substractOverdoorIfExistAndUpdateGlassArea = (
        overdoorGlass: GlassPartition | null,
        doorGlass: GlassPartition | null,
    ): void => {
        const pricingService = new PricingService();

        if (!overdoorGlass || !doorGlass) return;

        const overdoorGlassDimensions = overdoorGlass?.dimensions.copy();
        const doorGlassDimensions = doorGlass?.dimensions.copy();
        const partitionDimensions = this.dimensions.copy();

        const partitionArea = partitionDimensions.calculateArea().getValue();
        const doorGlassArea = doorGlassDimensions.area.toInches().getValue();
        const overdoorGlassArea = overdoorGlassDimensions.area
            .toInches()
            .getValue();

        const newPartitionArea =
            partitionArea - doorGlassArea - overdoorGlassArea;

        const glass = this.getActiveGlass() as unknown as GlassPartition;
        glass!.dimensions.area = new DimensionValue(newPartitionArea);

        const glassSum = pricingService.calculatePartitionGlassPrice();
        glass!.update({ sum: glassSum });
    };

    isDivider = (): boolean => {
        return this.structureType?.value === DividerPartitionCode;
    };

    isGlassPartition = (): boolean => {
        return this.structureType?.value === GlassPartitionCode;
    };

    isDoorPartition = (): boolean => {
        return this.structureType?.value === DoorPartitionCode;
    };

    isMultiFoldingPartition = (): boolean => {
        return this.structureType?.value === MultiFoldingPartitionCode;
    };

    getAllOverdoors = () => {
        return this.components.filter(
            (component) => component.type === "overdoor",
        );
    };

    getExtra = () => {
        return this.components.find((component) => component.type === "extra");
    };

    getAllDoors = () => {
        return this.components.filter((component) => component.type === "door");
    };

    getActiveDoor = (): Door => {
        return this.components.find(
            (component) => component.type === "door",
        ) as unknown as Door;
    };

    getActiveOverdoor = () => {
        return this.components.find(
            (component) => component.type === "overdoor",
        ) as unknown as Overdoor;
    };

    getActiveMount = () => {
        return this.components.find(
            (component) => component.type === "mounts",
        ) as unknown as Mounts;
    };

    getActiveGlass = () => {
        return this.components.find(
            (component) => component.type === "glassPartition",
        );
    };

    getActiveGlassThinkness = () => {
        const glass = this.getActiveGlass();
        return glass?.components.find(
            (component) => component.type === "glassThinknessPartition",
        );
    };

    getActiveOutOfSquare = () => {
        return this.components.find(
            (component) => component.type === "outOfSquare",
        );
    };
}
