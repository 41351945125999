import { useDictStore } from "@/stores/dictStore";
import { GlassPredicates, SystemPredicates } from "@/app/predicates";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import {
    NonSteelGlassPriceMultiplier,
    SteelGlassPriceMultiplier,
} from "@/app/constants/multipicators";

export class GlassDoorPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [
            installedSystemCode,
            stateCode,
            glassSortCode,
            glassThicknessCode,
        ] = args as [string, string, string, string];

        const pricesFromDict = this.dictStore.findGlassPrice(
            glassSortCode,
            glassThicknessCode,
            stateCode,
        );

        if (
            this.shouldUseZeroPrice(
                installedSystemCode,
                glassSortCode,
                glassThicknessCode,
            )
        ) {
            return this.getDefaultPrices();
        }

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        const multiplicator = SystemPredicates.isSteelSystem(
            installedSystemCode,
        )
            ? SteelGlassPriceMultiplier
            : NonSteelGlassPriceMultiplier;

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.price! * multiplicator,
            priceB2B: pricesFromDict.price! * multiplicator,
        };
    }

    private shouldUseZeroPrice(
        installedSystemCode: string,
        glassSortCode: string,
        glassThinknessCode: string,
    ) {
        return (
            SystemPredicates.isKompasSystem(installedSystemCode) &&
            GlassPredicates.isClearGlassType(glassSortCode) &&
            GlassPredicates.is1_4GlassThickness(glassThinknessCode)
        );
    }
}
