import { defineStore } from "pinia";
import { computed, ref, toRef } from "vue";

import {
    DoorNoneCode,
    GlassSortClearCode,
    GlassThicknessCode3_8,
} from "@/app/constants";
import {
    Door,
    DoorCut,
    GlassPartition,
    GlassThinknessPartition,
    Partition,
} from "@/app/domain";

import { DoorService } from "@/app/services/door.service";
import { Hardware } from "@/app/domain/entities/hardware";
import { usePartitionStore } from "./partition.store";

export const useDoorsStore = defineStore(
    "doors-store",
    () => {
        const partitionsStore = usePartitionStore();
        const activePartition = computed(
            () => partitionsStore.activePartitions,
        );

        const activeDoor = ref<Door | null>(null);
        const activeGlassDoor = ref<GlassPartition | null>();
        const activeGlassThinknessDoor = ref<GlassThinknessPartition | null>();

        const activeHardwares = ref<Hardware[]>([]);
        const activeDoorCuts = ref<DoorCut[]>([]);

        const doorType = computed(() => activeDoor.value?.structureType);

        const hasActiveDoor = computed(() => activeDoor.value !== null);
        const hasActiveHardwares = computed(
            () => activeHardwares.value.length > 0,
        );
        const hasActiveCuts = computed(() => activeDoorCuts.value.length > 0);

        const $reset = () => {
            activeDoor.value = null;
            activeGlassDoor.value = null;
            activeGlassThinknessDoor.value = null;
            activeHardwares.value = [];
            activeDoorCuts.value = [];
        };

        const $restoreDoors = () => {
            setActiveDoor(activePartition.value);
        };

        const setActiveDoor = (partition?: Partition) => {
            if (!partition) return;

            const door = partition?.getActiveDoor();
            const glass = door?.getActiveGlassPartition();
            const glassThinkness = door?.getActiveGlassThinkness();

            activeDoor.value = door;
            activeGlassDoor.value = glass;
            activeGlassThinknessDoor.value = glassThinkness;
        };

        const addDoor = () => {
            if (!activePartition.value) {
                return;
            }

            activeHardwares.value = [];

            const door = new Door({
                code: "door-1",
                structureType: DoorNoneCode,
                qty: 0,
            });

            activePartition.value.addComponent(door);
            activeDoor.value = door;

            addGlassDoor();
            addGlassThinknessDoor();
        };

        const addGlassDoor = () => {
            if (!activeDoor.value) {
                return;
            }

            const partitionGlass = activePartition.value?.getActiveGlass();
            const defaultStructureType =
                partitionGlass?.structureType || GlassSortClearCode;

            const doorGlass = new GlassPartition({
                structureType: defaultStructureType,
            });

            activeDoor.value?.addComponent(doorGlass);
            activeGlassDoor.value = doorGlass;
        };

        const addGlassThinknessDoor = () => {
            if (!activeGlassDoor.value) {
                return;
            }

            const partitionGlassThinkness =
                activePartition.value?.getActiveGlassThinkness();
            const defaultStructureType =
                partitionGlassThinkness?.structureType || GlassThicknessCode3_8;

            const doorGlassThinkness = new GlassThinknessPartition({
                structureType: defaultStructureType,
            });

            activeGlassDoor.value?.addComponent(doorGlassThinkness);
            activeGlassThinknessDoor.value = doorGlassThinkness;
        };

        const addDoorHardware = () => {
            console.log("useDoorsStore.addDoorHardware", doorType.value);
            if (!activeDoor.value || !doorType.value) {
                return;
            }

            const hardwares = DoorService.createDoorHardwares(doorType.value);
            activeDoor.value.replaceHardwares(hardwares);
            activeHardwares.value = hardwares;
        };

        const addDoorCuts = () => {
            console.log("useDoorsStore.addDoorCuts");

            if (!activeDoor.value || !doorType.value) {
                return;
            }

            const cuts = DoorService.createDoorCuts(doorType.value);
            activeDoor.value.replaceCuts(cuts);
            activeDoorCuts.value = cuts;
        };

        const syncDoorGlassWithPartition = () => {
            if (
                !activePartition.value ||
                !activeDoor.value ||
                !activeGlassDoor.value ||
                !activeGlassThinknessDoor.value
            ) {
                return;
            }

            const partitionGlass = activePartition.value.getActiveGlass();
            const partitionGlassThinkness =
                activePartition.value.getActiveGlassThinkness();

            activeGlassDoor.value.structureType = toRef(
                partitionGlass?.structureType || GlassSortClearCode,
            );
            activeGlassThinknessDoor.value.structureType = toRef(
                partitionGlassThinkness?.structureType || GlassThicknessCode3_8,
            );
        };

        return {
            addDoor,
            addGlassDoor,
            addGlassThinknessDoor,
            addDoorHardware,
            addDoorCuts,
            syncDoorGlassWithPartition,
            setActiveDoor,
            $reset,
            $restoreDoors,
            hasActiveDoor,
            hasActiveHardwares,
            hasActiveCuts,
            activeDoor,
            activeGlassDoor,
            activeHardwares,
            activeGlassThinknessDoor,
            activeDoorCuts,
        };
    },
    { persist: true },
);
