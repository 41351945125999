import { z } from "zod";
import { ValidationUtils } from "./utils";

export const EstimateSchema = z.object(
    {
        dealTypeCode: ValidationUtils.nonEmpty(),
        installedSystemCode: ValidationUtils.nonEmpty(),
        client: z.object({
            name: ValidationUtils.nonEmpty(),
        }),
        colorCode: ValidationUtils.nonEmpty(),
        shippingState: ValidationUtils.nonEmpty(),
        managerCode: z.any(),
    },
    {
        message:
            "Validation error\nSome of the required fields are not filled correctly",
    },
);
