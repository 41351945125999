<template>
  <select-dict
    v-model="installationUsageCode"
    :select-list="installationUsageFiltered"
  />
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";
import { useDictStore } from "@/stores/dictStore";

type InstallationUssageSelectProps = {
  installationPlaceCode: string;
};

const props = defineProps<InstallationUssageSelectProps>();
const dictStore = useDictStore();

const installationUsageCode = defineModel<string>({ default: "" });
const installationUsageFiltered = computed(() =>
  dictStore.getInstallationUsage(props.installationPlaceCode),
);

watch(
  () => props.installationPlaceCode,
  () => {
    installationUsageCode.value = "";
  },
);
</script>

<style lang="scss" scoped></style>
