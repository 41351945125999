import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";

interface DoorCutConfig extends IComponentConfig {
    defaultQty: number;
}

export class DoorCut extends ReactiveComponent {
    defaultQty = 0;

    constructor(config: DoorCutConfig) {
        super({ ...config, type: "doorCut" });

        this.defaultQty = config.defaultQty || 0;
    }

    public syncQtyWithHardwareStructureCode = (
        hardwareStructureCode?: string,
    ) => {
        if (!hardwareStructureCode) {
            return;
        }

        this.update({
            qty: this.isHardwareNoneStructureCode(hardwareStructureCode)
                ? 0
                : this.defaultQty,
        });
    };

    /**
     * @override ReactiveComponent.serialize
     */
    public serialize(serializeParent = true) {
        return {
            ...super.serialize(serializeParent),
            defaultQty: this.defaultQty,
        };
    }

    private isHardwareNoneStructureCode = (structureCode?: string) =>
        structureCode?.includes("none");
}
