import { useDictStore } from "@/stores/dictStore";
import { SystemPredicates } from "@/app/predicates";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import {
    NonSteelGlassPriceMultiplier,
    SteelGlassPriceMultiplier,
} from "@/app/constants/multipicators";

export class GlassOverdoorPricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [
            installedSystemCode,
            stateCode,
            glassSortCode,
            glassThicknessCode,
        ] = args as [string, string, string, string];

        const pricesFromDict = this.dictStore.findGlassPrice(
            glassSortCode,
            glassThicknessCode,
            stateCode,
        );

        const multiplicator = SystemPredicates.isSteelSystem(
            installedSystemCode,
        )
            ? SteelGlassPriceMultiplier
            : NonSteelGlassPriceMultiplier;

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price!,
            priceB2C: pricesFromDict.price! * multiplicator,
            priceB2B: pricesFromDict.price! * multiplicator,
        };
    }
}
