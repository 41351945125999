import {
    DoorNoneCode,
    DoubleDoors,
    KompasDoors,
    SingleDoors,
    SlidingDoors,
    SwingDoors,
} from "@/app/constants";
import {
    IComponentConfig,
    IStructureComponent,
} from "@/app/@types/base-component";

import { ReactiveComponent } from "./base-component";
import { ReactiveComponentDimensions } from "./dimensions";

import { Partition } from "./partition";
import { DoorCut } from "./door-cut";

export class Door extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "door" });
    }

    replaceHardwares = (components: IStructureComponent[]) => {
        this.replaceComponents("hardware", components);
    };

    replaceCuts = (components: IStructureComponent[]) => {
        this.replaceComponents("doorCut", components);
    };

    resetDimensions = () => {
        this.update({ dimensions: new ReactiveComponentDimensions() });
    };

    initDoorHeightFromPartition = (partition: Partition) => {
        if (!this.dimensions.height.isEmpty()) {
            return;
        }

        const partitionHeight = partition.dimensions.height.copy().getValues();

        this.dimensions.height.update({ ...partitionHeight });
    };

    updateDoorWidth = (width: number) => {
        this.dimensions.width.update({ ...this.dimensions.width, ft: width });
    };

    updateDoorHeight = (height: number) => {
        this.dimensions.height.update({
            ...this.dimensions.height,
            ft: height,
        });
    };

    isEditable = (): boolean => {
        return this.structureType?.value !== DoorNoneCode;
    };

    isDoorNone = (): boolean => {
        return this.structureType?.value === DoorNoneCode;
    };

    isSingleDoor = () => {
        if (!this.structureType?.value) {
            return null;
        }

        return SingleDoors.includes(this.structureType?.value);
    };

    isDoubleDoor = () => {
        if (!this.structureType?.value) {
            return false;
        }

        return DoubleDoors.includes(this.structureType?.value);
    };

    isSwingDoor = () => {
        if (!this.structureType?.value) {
            return null;
        }

        return SwingDoors.includes(this.structureType?.value);
    };

    isSlidingDoor = () => {
        if (!this.structureType?.value) {
            return null;
        }

        return SlidingDoors.includes(this.structureType?.value);
    };

    isKompasDoor = () => {
        if (!this.structureType?.value) {
            return null;
        }

        return KompasDoors.includes(this.structureType?.value);
    };

    getActiveGlassPartition = () => {
        return this.components.find(
            (component) => component.type === "glassPartition",
        );
    };

    getActiveGlassThinkness = () => {
        const glass = this.getActiveGlassPartition();
        return glass?.components.find(
            (component) => component.type === "glassThinknessPartition",
        );
    };

    getActiveHardwares = () => {
        return this.components.filter(
            (component) => component.type === "hardware",
        );
    };

    getActiveDoorCuts = () => {
        return this.components.filter(
            (component) => component.type === "doorCut",
        );
    };
}
