import { StructureItem } from "@/models/structure";
import {
    BaseTransformer,
    convertStructureItemToReactiveComponent,
    getCalculationItem,
    getEmptyStructure,
} from "./base.transformers";
import { Mounts } from "../entities/mounts";
import { unwrapRef } from "@/utils/reactive";
import { MountHardware } from "../entities/hardware";
import { HardwareTransformer } from "./hardware.transform";

export const MountsTransformer: BaseTransformer<StructureItem, Mounts> = {
    toOld(mounts: Mounts) {
        if (!mounts) return getEmptyStructure();

        const hardwares = mounts.getHardwares() as unknown as MountHardware[];

        return {
            id: unwrapRef(mounts.id) || "",
            code: unwrapRef(mounts.code) || "",
            structureTypeCode: unwrapRef(mounts.structureType) || "",
            title: unwrapRef(mounts.title) || "",
            qty: unwrapRef(mounts.qty) || 0,
            type: unwrapRef(mounts.type) || "",
            dimensions: {
                width: {
                    ft: mounts.dimensions.width.ft,
                    inch: mounts.dimensions.width.inch,
                    fractionA: mounts.dimensions.width.fractionDivisor,
                    fractionB: mounts.dimensions.width.fractionDividend,
                },
                height: {
                    ft: mounts.dimensions.height.ft,
                    inch: mounts.dimensions.height.inch,
                    fractionA: mounts.dimensions.height.fractionDivisor,
                    fractionB: mounts.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: mounts.dimensions.area.toSquareFt().format(),
            },
            glassSortCode: "",
            glassThicknessCode: "",
            hardwares: hardwares.map(HardwareTransformer.toOld),
            note: "",
            outOfSquareQty: 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "", // glass , aluminum sheet, other
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    },
    fromOld(mountsConfig: StructureItem, calculations) {
        const calculationItems = getCalculationItem(
            calculations,
            undefined,
            "mounts",
        );

        const mounts = convertStructureItemToReactiveComponent(
            mountsConfig,
            calculationItems?.subitems.items || [],
            Mounts,
        ) as Mounts;

        const hardwares = mountsConfig.hardwares.map((hardware) =>
            HardwareTransformer.fromOld(
                hardware,
                calculationItems?.subitems.items || [],
            ),
        );

        mounts.replaceMountHardwares(hardwares);

        return mounts;
    },
};
