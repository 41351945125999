import { defineStore } from "pinia";
import { computed, ref } from "vue";

import {
    OutOfSquareCode,
    OverdoorNoneCode,
    OverdoorTransomCode,
    SheetMaterialCode,
} from "@/app/constants";
import { Hardware, Overdoor, OverdoorMaterial, Partition } from "@/app/domain";

import { OverdoorService } from "@/app/services/overdoor.service";
import { OverdoorAsset } from "@/app/domain/entities/overdoor-asset";
import { OutOfSquare } from "@/app/domain/entities/out-of-square";
import { usePartitionStore } from "./partition.store";
import { useDoorsStore } from "./doors.store";

export const useOverdoorStore = defineStore(
    "overdoor-store",
    () => {
        const partitionsStore = usePartitionStore();
        const doorsStore = useDoorsStore();

        const activePartition = computed(
            () => partitionsStore.activePartitions,
        );

        const activeDoor = computed(() => doorsStore.activeDoor);
        const activeOverdoor = ref<Overdoor | null>(null);
        const activeTransomHardwares = ref<Hardware[]>([]);
        const activeOverdoorMaterials = ref<OverdoorMaterial[]>([]);

        const activeOverdoorAssets = ref<OverdoorAsset | null>(null);
        const activeOverdoorOutOfSquare = ref<OutOfSquare | null>(null);

        const activeGlassOverdoor = computed(() =>
            activeOverdoorMaterials.value.at(0),
        );
        const activeGlassThinknessOverdoor = computed(() =>
            activeOverdoorMaterials.value.at(1),
        );

        const overdoorMaterialType = ref<string | null>("glass");

        const overdoorType = computed(
            () => activeOverdoor.value?.structureType,
        );

        const hasActiveOverdoor = computed(() => activeOverdoor.value !== null);
        const hasActiveOverdoorMaterial = computed(
            () => activeOverdoorMaterials.value.length > 0,
        );

        const $reset = () => {
            activeOverdoor.value = null;
            overdoorMaterialType.value = null;
            activeTransomHardwares.value = [];
            activeOverdoorMaterials.value = [];
            activeOverdoorAssets.value = null;
            activeOverdoorOutOfSquare.value = null;
        };

        const $restoreOverdoors = () => {
            setActiveOverdoor(activePartition.value);
        };

        const setActiveOverdoor = (partition?: Partition) => {
            if (!partition) return;

            const overdoor = partition?.getActiveOverdoor();
            const overdoorMaterial = overdoor?.getMaterial();
            const overdoorOutOfSquare = overdoor?.getOutOfSquare();

            activeOverdoor.value = overdoor;
            activeOverdoorMaterials.value = overdoorMaterial;
            activeOverdoorOutOfSquare.value = overdoorOutOfSquare;
        };

        const addOverdoor = () => {
            if (!activePartition.value) {
                return;
            }

            const overdoor = new Overdoor({
                structureType: detectOverdoorType(),
                qty: 1,
            });

            activePartition.value.addComponent(overdoor);
            activeOverdoor.value = overdoor;
        };

        const addOverdoorMaterial = () => {
            if (!activeOverdoor.value || !activePartition.value) return;

            const materials = OverdoorService.createOverdoorMaterial(
                overdoorMaterialType.value || "glass",
                activePartition.value,
            );

            activeOverdoor.value.replaceMaterial(materials);
            activeOverdoorMaterials.value = materials;
        };

        const addTransomHardware = () => {
            if (!activeOverdoor.value || !overdoorType.value) {
                return;
            }

            // const hardwares = DoorService.createDoorHardwares(fillerType.value);
            // activeFiller.value.replaceHardwares(hardwares);
            // activeFillerHardwares.value = hardwares;
        };

        const addOverdoorAsset = () => {
            if (!activeOverdoor.value) {
                return;
            }

            const asset = new OverdoorAsset({
                qty: 1,
            });

            activeOverdoor.value.addComponent(asset);
            activeOverdoorAssets.value = asset;
        };

        const addOverdoorOutOfSquare = () => {
            if (!activeOverdoor.value) {
                return;
            }

            const outOfSquare = new OutOfSquare({
                code: OutOfSquareCode,
                structureType: OverdoorNoneCode,
                qty: 0,
            });

            activeOverdoor.value.addComponent(outOfSquare);
            activeOverdoorOutOfSquare.value = outOfSquare;
        };

        const detectOverdoorType = () => {
            if (!activeDoor.value || !activePartition.value) {
                return OverdoorNoneCode;
            }

            if (activeDoor.value.isDoorNone()) {
                return OverdoorNoneCode;
            }

            const doorPartitionHeightDiff =
                activePartition.value.dimensions.height.compare(
                    activeDoor.value.dimensions.height,
                );

            if (doorPartitionHeightDiff > 0) {
                return OverdoorTransomCode;
            }

            return OverdoorNoneCode;
        };

        const syncOverdoorGlassWithPartition = () => {
            if (
                !activePartition.value ||
                !activeOverdoor.value ||
                !activeOverdoorMaterials.value
            ) {
                return;
            }

            if (overdoorMaterialType.value === SheetMaterialCode) {
                return;
            }

            const glass = activePartition.value.getActiveGlass();
            const glassThickness =
                activePartition.value.getActiveGlassThinkness();

            const overdoorGlass = activeOverdoorMaterials.value[0];
            const overdoorGlassThickness = activeOverdoorMaterials.value[1];

            overdoorGlass.structureType = glass?.structureType;
            overdoorGlassThickness.structureType =
                glassThickness?.structureType;
        };

        return {
            addOverdoor,
            addOverdoorMaterial,
            addTransomHardware,
            addOverdoorAsset,
            addOverdoorOutOfSquare,
            detectOverdoorType,
            syncOverdoorGlassWithPartition,
            setActiveOverdoor,
            $reset,
            $restoreOverdoors,
            activeOverdoorAssets,
            activeOverdoorOutOfSquare,
            activeOverdoor,
            activeTransomHardwares,
            activeOverdoorMaterials,
            overdoorMaterialType,
            hasActiveOverdoor,
            hasActiveOverdoorMaterial,
            activeGlassOverdoor,
            activeGlassThinknessOverdoor,
        };
    },
    { persist: true },
);
