import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { Door } from "./door";
import { DoorCut } from "./door-cut";
import { unwrapRef } from "@/utils/reactive";

export class Hardware extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "hardware" });
    }

    getRelatedCut(): DoorCut | null {
        const door = this.parent as unknown as Door;

        if (!door) {
            return null;
        }

        const cuts = door.getActiveDoorCuts();

        return cuts.find(
            (cut) => cut.typeCode === this.typeCode,
        ) as unknown as DoorCut;
    }

    isEmpty(): boolean {
        return unwrapRef(this.structureType).includes("none") || false;
    }
}

export class MountHardware extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "mount-hardware" });
    }
}
