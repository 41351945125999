import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { SummaryBlock as SummaryBlockItem } from "@/models/summary";

import { useNewEstimateStore } from "./estimateStore";
import { useDoorsStore } from "./doors.store";
import { usePartitionStore } from "./partition.store";
import { useSpacesStore } from "./spaces.store";
import { useOverdoorStore } from "./overdoor.store";
import { useDeliveryStore } from "./delivery.store";
import { useExtraStore } from "./extra.store";
import { useOverheadStore } from "./overhead.store";
import { useMountsStore } from "./mounts.store";
import { SummaryService } from "@/services/summaryService";
import { getEstimate } from "@/utils/api";
import { useDictStore } from "../dictStore";
import { EstimateConverterService } from "@/app/services/legasy-estimate-converter.service";
import { unwrapRef } from "@/utils/reactive";
import router from "@/router";
import { Partition } from "@/app/domain";
import { usePartitionGlassArea } from "@/app/composables/usePartitionGlassArea";

export const useActionsStrore = defineStore(
    "actions",
    () => {
        const estimateStore = useNewEstimateStore();
        const spacesStore = useSpacesStore();
        const partitionsStore = usePartitionStore();
        const doorsStore = useDoorsStore();
        const overdoorsStore = useOverdoorStore();
        const deliveryStore = useDeliveryStore();
        const extraStore = useExtraStore();
        const mountsStore = useMountsStore();
        const overheadsStore = useOverheadStore();
        const dictStore = useDictStore();

        const estimate = computed(() => estimateStore.estimate);

        const activeSpace = computed(() => spacesStore.activeSpace);
        const spaces = computed(() => spacesStore.spaces);
        const delivery = computed(() => deliveryStore.activeDelivery);

        const summary = ref<SummaryBlockItem | null>(null);

        const resetAllStores = () => {
            estimateStore.$reset();
            spacesStore.$reset();
            partitionsStore.$reset();
            doorsStore.$reset();
            overdoorsStore.$reset();
            deliveryStore.$reset();
            extraStore.$reset();
            mountsStore.$reset();
            overheadsStore.$reset();
        };

        const addAnotherPartition = () => {
            partitionsStore.$reset();
            doorsStore.$reset();
            overdoorsStore.$reset();
            mountsStore.$reset();
        };

        const addAnotherSpace = () => {
            spacesStore.addSpace();
            addAnotherPartition();
        };

        const editPartition = () => router.push(`/calculation/2`);

        const removePartition = (id: string) => {
            const spaceWithPartition = spaces.value.find((space) => {
                return Boolean(space.getPartitionById(id));
            });

            const allPartitions = spaceWithPartition?.getAllPartitions();

            if (spaceWithPartition?.id === activeSpace.value?.id) {
                if (allPartitions?.length === 1) {
                    partitionsStore.$reset();
                    doorsStore.$reset();
                    overdoorsStore.$reset();
                    mountsStore.$reset();

                    spacesStore.removeSpace(spaceWithPartition);

                    router.push(`/calculation/2`);
                } else {
                    spaceWithPartition?.removePartition(id);
                    activeSpace.value?.removePartition(id);

                    const lastPartitions =
                        activeSpace.value?.getLastPartition();

                    setLastPartitionIsActive(lastPartitions);
                }
            } else {
                if (allPartitions?.length === 1) {
                    spacesStore.removeSpace(spaceWithPartition);
                } else {
                    spaceWithPartition?.removePartition(id);
                }
            }

            reloadSummary();
        };

        const setLastPartitionIsActive = (partition?: Partition) => {
            if (!partition) return;

            partitionsStore.setActivePartition(partition);
            doorsStore.setActiveDoor(partition);
            overdoorsStore.setActiveOverdoor(partition);
            mountsStore.setActiveMounts(partition);
        };

        const reloadSummary = () => {
            summary.value = SummaryService.fromEstimate(
                estimate.value,
                spaces.value,
                delivery.value,
            );
        };

        const loadEstimate = async (estimateCode: string) => {
            const estimateData = await getEstimate(estimateCode);

            resetAllStores();
            reloadPrices(estimateData.estimate?.info?.priceDateFormated);

            const estimate = EstimateConverterService.convertToNewFormat(
                estimateData.estimate,
                estimateData.calculation,
            );

            const spaces = EstimateConverterService.converToNewSpaces(
                estimateData.estimate.spaces,
                estimateData.calculation.subitems.items,
            );

            estimateStore.$restoreEstimate(estimate);
            spacesStore.$restoreSpaces(spaces);
            partitionsStore.$restorePartitions();
            doorsStore.$restoreDoors();
            overdoorsStore.$restoreOverdoors();
            mountsStore.$restoreMounts();
        };

        const saveAsDraft = () => {
            estimateStore.persist();
            spacesStore.persist();
            partitionsStore.persist();
            doorsStore.persist();
            overdoorsStore.persist();
            deliveryStore.persist();
            extraStore.persist();
            mountsStore.persist();
            overheadsStore.persist();
        };

        const reloadPrices = (date) => {
            dictStore.loadAllDictsByDate(date);
        };

        return {
            summary,
            resetAllStores,
            removePartition,
            reloadSummary,
            addAnotherPartition,
            editPartition,
            addAnotherSpace,
            loadEstimate,
            saveAsDraft,
        };
    },
    { persist: false },
);
