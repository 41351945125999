<template>
  <StepTotalPrice
    v-if="activeOverdoor"
    title="Page total"
    :price="activeOverdoor.sum"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { StepTotalPrice } from "@/shared";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { PricingService } from "@/app/services/pricing.service";

const overdoorStore = useOverdoorStore();
const pricingService = new PricingService();
const activeOverdoor = computed(() => overdoorStore.activeOverdoor);

watch(
  () => [
    activeOverdoor.value?.components,
    activeOverdoor.value?.dimensions.area,
  ],
  () => {
    if (!activeOverdoor.value) {
      return;
    }

    const price = pricingService.getOverdoorPrice();

    activeOverdoor.value?.update({
      price,
    });
  },
  { immediate: true, deep: true },
);

watch(
  () => [activeOverdoor.value?.price],
  () => {
    const sum = pricingService.calculateOverdoorTotalPrice();
    activeOverdoor.value?.update({ sum });
  },
  { deep: true },
);

onMounted(() => {
  const price = pricingService.getOverdoorPrice();
  const sum = pricingService.calculateOverdoorTotalPrice();

  activeOverdoor.value?.update({ sum });
  activeOverdoor.value?.update({
    price,
  });
});
</script>
