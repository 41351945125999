import { computed, isRef } from "vue";
import { IComponentConfig } from "@/app/@types/base-component";

import { ReactiveComponent } from "./base-component";
import { Door } from "./door";
import { Partition } from "./partition";
import { Overdoor } from "./overdoor";
import { Overheads } from "./overhead";
import { DimensionValue } from "./dimensions";
import { Mounts } from "./mounts";
import { Extra } from "./extra";
import { unwrapRef } from "@/utils/reactive";

export class Space extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "space" });
    }

    getPartitionById = (id: string) => {
        return this.components.find(
            (component) => component.id === id,
        ) as unknown as Partition;
    };

    getAllPartitions = () => {
        return this.components.filter(
            (component) => component.type === "partition",
        ) as unknown as Partition[];
    };

    getLastPartition = () => {
        const partitions = this.getAllPartitions();

        return partitions[partitions.length - 1];
    };

    getAllDoors = () => {
        return this.components.filter(
            (component) => component.type === "door",
        ) as unknown as Door[];
    };

    getAllOverdoors = () => {
        return this.components.filter(
            (component) => component.type === "overdoor",
        ) as unknown as Overdoor[];
    };

    getExtra = () => {
        return this.components.find(
            (component) => component.type === "extra",
        ) as unknown as Extra;
    };

    getOverheads = () => {
        return this.components.find(
            (component) => component.type === "overheads",
        ) as unknown as Overheads;
    };

    getActivePartition = (): Partition => {
        return this.components.find(
            (component) => component.type === "partition",
        ) as unknown as Partition;
    };

    getActiveDoor = (): Door => {
        const activePartition = this.getActivePartition();
        return activePartition.getActiveDoor();
    };

    getActiveOverdoor = (): Overdoor => {
        const activePartition = this.getActivePartition();
        return activePartition.getActiveOverdoor();
    };

    getActiveMount = (): Mounts => {
        const activePartition = this.getActivePartition();
        return activePartition.getActiveMount();
    };

    calculateTotalAreaSqFt = (): DimensionValue => {
        const partitions = this.getAllPartitions();

        const totalArea = partitions.reduce((acc, partition) => {
            const partitionArea = computed(() =>
                partition.dimensions.area.getValue(),
            );

            const partitionQty = isRef(partition.qty)
                ? partition.qty.value
                : partition.qty;

            return acc + partitionArea.value * partitionQty;
        }, 0);

        return new DimensionValue(totalArea);
    };

    replaceOverheads = (overheads: Overheads): void => {
        this.replaceComponents("overheads", [overheads]);
    };

    removePartition = (id: string) => {
        this.removeComponent(unwrapRef(id), "partition");
    };
}
