import { getFromDictByCode, useDictStore } from "@/stores/dictStore";
import { IComponentConfig } from "@/app/@types/base-component";
import {
    DoubleFabricProducedDoorTypeCode,
    HandleTypeCode,
    LockTypeCode,
    PatchesSetTypeCode,
    RollerSetTypeCode,
    SingleFabricProducedDoorTypeCode,
    UHolderTypeCode,
} from "@/app/constants/hardwares";
import { computed } from "vue";

export type ConfigBuilder = {
    valueNone(): ConfigBuilder;
    qty(value: number): ConfigBuilder;
    build(): IComponentConfig;
};

export const createRollerSetConfig = () => {
    return createHadwareConfig(RollerSetTypeCode);
};

export const createPatchesSetConfig = () => {
    return createHadwareConfig(PatchesSetTypeCode);
};

export const createUHolderConfig = () => {
    return createHadwareConfig(UHolderTypeCode);
};

export const createHandleConfig = () => {
    return createHadwareConfig(HandleTypeCode);
};

export const createLockConfig = () => {
    return createHadwareConfig(LockTypeCode);
};

export const createSingleFabricProducedDoorConfig = () => {
    return createHadwareConfig(SingleFabricProducedDoorTypeCode);
};

export const createDoubleFabricProducedDoorConfig = () => {
    return createHadwareConfig(DoubleFabricProducedDoorTypeCode);
};

const createHadwareConfig = (hardwareTypeCode: string): ConfigBuilder => {
    const dictStore = useDictStore();

    const hardwares = computed(() =>
        dictStore.getHardwareValues(hardwareTypeCode),
    );

    const structureType =
        hardwares.value.length > 1 ? hardwares.value[1].code : "";

    const title = computed(
        () =>
            getFromDictByCode(
                dictStore.getHardwareTypes("door"),
                hardwareTypeCode,
            )?.title,
    );

    const config = {
        title: title.value,
        structureType,
        typeCode: hardwareTypeCode,
        qty: 1,
    };

    const builder = {
        valueNone() {
            // Подразумевается, что первый элемент в списке - это none
            const structureTypeNone =
                hardwares.value?.length > 1 ? hardwares.value[0].code : "";
            config.structureType = structureTypeNone;
            return builder;
        },
        qty(value: number) {
            config.qty = value;
            return builder;
        },
        build() {
            return config;
        },
    };

    return builder;
};
