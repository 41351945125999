<template>
  <div class="select" ref="target">
    <div class="select__head">
      <input
        @input="handlerInputValue"
        @focus="focusInput"
        type="text"
        v-model="inputValue"
        :placeholder="props.placeholder"
        @keyup.down="onArrowDown"
        @keyup.up="onArrowUp"
        @keyup.enter="onEnter"
        @click="showAll"
      />
    </div>
    <ul
      :class="[
        'autocomplete-result select__list',
        { 'select__list--active': isActiveSelect },
      ]"
      ref="scrollContainer"
    >
      <li
        ref="options"
        v-for="(item, i) in dataSelectItems"
        :key="item.title"
        @click="changeSelectValue(item)"
        class="select__item autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { onClickOutside } from "@vueuse/core";

const props = defineProps([
  "modelValue",
  "placeholder",
  "selectList",
  "selectFirst",
]);

const emit = defineEmits<{
  "update:modelValue": [value: string];
  selectChanged: [value: string];
}>();

const inputValue = ref(props.modelValue);
const dataSelectItems = computed(() =>
  props.selectList.filter((item) =>
    inputValue.value
      ? item?.title?.toLowerCase().includes(inputValue.value?.toLowerCase())
      : true,
  ),
);

const target = ref(null);
const isActiveSelect = ref(false);
const arrowCounter = ref(0);
const results = ref([]);
const scrollContainer = ref(null);
const options = ref(null);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
onClickOutside(target, () => {
  isActiveSelect.value = false;
});

if (props.selectFirst && !inputValue.value && !!dataSelectItems.value) {
  inputValue.value = dataSelectItems.value[0]?.title;
  emit("update:modelValue", inputValue.value);
}

const focusInput = () => {
  if (inputValue.value > 0) {
    isActiveSelect.value = false;
  }
};

const handlerInputValue = () => {
  if (inputValue.value === "") {
    isActiveSelect.value = true;
  }
  emit("update:modelValue", inputValue.value);
};

const changeSelectValue = (value) => {
  console.debug("changeSelectValue", value);
  inputValue.value = value.title;
  emit("update:modelValue", inputValue.value);
  isActiveSelect.value = false;
};

const onArrowDown = (event) => {
  // console.log('onArrowDown');
  isActiveSelect.value = true;
  emit("update:modelValue", inputValue.value);

  if (arrowCounter.value < dataSelectItems.value.length - 1) {
    arrowCounter.value = arrowCounter.value + 1;
    fixScrolling();
  }
};
const onArrowUp = () => {
  // console.log('onArrowUp');
  if (arrowCounter.value > 0) {
    arrowCounter.value = arrowCounter.value - 1;
  }

  fixScrolling();
};
const onEnter = () => {
  // console.log('onEnter');
  // console.log(arrowCounter.value);
  inputValue.value = dataSelectItems.value[arrowCounter.value].title;
  emit("update:modelValue", inputValue.value);
  isActiveSelect.value = false;
  arrowCounter.value = -1;
};
const showAll = () => {
  // console.log('showAll');
  isActiveSelect.value = false;
  isActiveSelect.value = !isActiveSelect.value;
  isActiveSelect.value
    ? (results.value = dataSelectItems.value)
    : (results.value = []);
  emit("update:modelValue", inputValue.value);
};

const fixScrolling = () => {
  const liH = options.value[arrowCounter.value].clientHeight;
  scrollContainer.value.scrollTop = liH * arrowCounter.value;
};
</script>

<style scoped>
.select {
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 25px;
  background: #fff;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 35px;
}

.select__head {
  width: 100%;
  min-height: 35px;
  padding: 9px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.select__list {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease;
  box-shadow: 0px 0px 24px 0px rgba(32, 92, 148, 0.15);
  max-height: 350px;
  overflow-y: auto;
  border-radius: 5px;
}

.select__list.select__list--active {
  top: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s ease;
}

.select__list li {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.5s ease;
}

.select__list li:hover {
  background-color: #2491eb;
  transition: 0.5s ease;
  color: #fff;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active {
  background-color: #2491eb;
  color: #fff;
}
</style>
