class IndexedDBStorageService {
    private readonly dbName = "piniaStorage";
    private readonly storeName = "stores";
    private readonly version = 1;
    private db: IDBDatabase | null = null;

    /**
     * Инициализация базы данных
     */
    async init(): Promise<void> {
        if (this.db) {
            return;
        }

        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.version);

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = () => {
                this.db = request.result;
                resolve();
            };

            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName);
                }
            };
        });
    }

    /**
     * Сохранение данных в IndexedDB
     */
    async setItem(key: string, value: string): Promise<void> {
        await this.init();

        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(
                [this.storeName],
                "readwrite",
            );
            const store = transaction.objectStore(this.storeName);
            const request = store.put(value, key);

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = () => {
                resolve();
            };
        });
    }

    /**
     * Получение данных из IndexedDB
     */
    async getItem(key: string): Promise<string | null> {
        await this.init();

        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(
                [this.storeName],
                "readonly",
            );
            const store = transaction.objectStore(this.storeName);
            const request = store.get(key);

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = () => {
                resolve(request.result || null);
            };
        });
    }

    /**
     * Удаление данных из IndexedDB
     */
    async removeItem(key: string): Promise<void> {
        await this.init();

        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(
                [this.storeName],
                "readwrite",
            );
            const store = transaction.objectStore(this.storeName);
            const request = store.delete(key);

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = () => {
                resolve();
            };
        });
    }

    /**
     * Очистка всех данных в IndexedDB
     */
    async clear(): Promise<void> {
        await this.init();

        return new Promise((resolve, reject) => {
            const transaction = this.db!.transaction(
                [this.storeName],
                "readwrite",
            );
            const store = transaction.objectStore(this.storeName);
            const request = store.clear();

            request.onerror = () => {
                reject(request.error);
            };

            request.onsuccess = () => {
                resolve();
            };
        });
    }
}

// Экспортируем единственный экземпляр сервиса
export default new IndexedDBStorageService();
