import { PiniaPluginContext } from "pinia";

import { PiniaSerializerService } from "@/app/services/pinia-serializer.service";
import indexDb from "./index-db";

export const usePiniaPersist = ({ store, options }: PiniaPluginContext) => {
    const state = store.$state;
    const needPersist = options?.persist ?? false;

    store.persist = async () => {
        if (!needPersist) return;

        const serializedState = PiniaSerializerService.serialize(state);
        await indexDb.setItem(store.$id, serializedState);
    };

    store.restore = async () => {
        if (!needPersist) return;

        const serializedState = await indexDb.getItem(store.$id);

        if (!serializedState) {
            return;
        }

        const deserializedStore =
            PiniaSerializerService.deserialize(serializedState);

        store.$patch(deserializedStore);
    };

    store.restore();
};
