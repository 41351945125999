<template>
  <select-dict
    v-model="overdoorType"
    :selectList="overdoorStructureTypes"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useDoorsStore } from "@/stores/new/doors.store";
import { usePartitionStore } from "@/stores/new/partition.store";
import { OverdoorNoneCode } from "@/app/constants";

const overdoorType = defineModel<string>();

const dictStore = useDictStore();
const doorStore = useDoorsStore();
const partitionStore = usePartitionStore();

const activePartition = computed(() => partitionStore.activePartitions);

const doorType = computed(() => doorStore.activeDoor?.structureType || "");

const overdoorStructureTypes = computed(() => {
  const types = dictStore.getStructureTypes(["filler", doorType.value]);

  if (activePartition.value?.isDivider()) {
    return types.filter((type) => type.code === OverdoorNoneCode);
  }

  return types;
});
</script>

<style lang="scss" scoped></style>
