<template>
  <select-dict
    v-model="glassSort"
    :selectList="glassSorts"
    :select-first-item="true"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import { useDictStore } from "@/stores/dictStore";
import SelectDict from "@/components/UI/SelectDict.vue";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

const dictStore = useDictStore();
const estimateStore = useNewEstimateStore();

const stateCode = computed(() => estimateStore.estimate.shippingState);

const glassSorts = dictStore.findGlassSort(stateCode.value);
const glassSort = defineModel<string>();
</script>

<style lang="scss" scoped></style>
