<template>
  <select-dict v-model="colorCode" :select-list="finishColors" />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";
import { useDictStore } from "@/stores/dictStore";

type FinishColorSelectProps = {
  installedSystemCode: string;
};

const props = defineProps<FinishColorSelectProps>();
const dictStore = useDictStore();

const colorCode = defineModel<string>({ default: "bl" });
const finishColors = computed(() =>
  dictStore.getFinishColors(props.installedSystemCode),
);
</script>

<style lang="scss" scoped></style>
