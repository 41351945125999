<template>
  <span v-if="!isManager">
    {{ estimate.estimatePrice.getSystemPrice(estimate.dealTypeCode).format() }}
  </span>
  <span v-else>
    {{ estimate.subtotal.getSystemPrice(estimate.dealTypeCode).format() }}
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useUserStore } from "@/stores/userStore";

const userStore = useUserStore();
const estimateStore = useNewEstimateStore();

const estimate = computed(() => estimateStore.estimate);
const isManager = computed(() => userStore.isSalesManager);
</script>

<style lang="scss" scoped></style>
